import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import StorageService from '../../Services/storage/storage.service';
import {
    deleteDestination,
    fetchDestinationDetails,
    updateDestinationDetail,
} from '../../Redux/Actions/destinationActions';
import { destinationConstants } from '../../utils/constants/DestinationConstant';
import { DESTINATION_INITIAL_STATE } from '../../Redux/Constants/destinationInitialState';
import {
    IDestinationDetail,
    IFilter,
} from '../../utils/Interface/DestinationInterface';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import DestinationModal from './DestinationModal';
import Constants from '../../utils/Constants';
import { setDestinationFilter } from '../../Redux/Reducer/destinationSlice';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';

const DestinationDetails = () => {
    const { filter, loader } = useAppSelector(state => state.destination);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isDestinationModalOpen,
        open: openDestinationModal,
        close: closeDestinationModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [destinationDetails, setDestinationDetails] = useState<
        IDestinationDetail[]
    >([]);
    const [selectedDestination, setSelectedDestination] =
        useState<IDestinationDetail>(
            DESTINATION_INITIAL_STATE.createDestinationInitial,
        );

    const fetchDestinationData = (
        destinationFilter: IFilter = filter,
    ): void => {
        dispatch(
            fetchDestinationDetails(setDestinationDetails, destinationFilter),
        );
    };

    const handleEditDestinationDetail = (
        destination: IDestinationDetail,
    ): void => {
        setSelectedDestination(destination);
        openDestinationModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = DESTINATION_INITIAL_STATE.filter;
        fetchDestinationData(updatedFilter);
        setSearchQuery('');
        dispatch(setDestinationFilter(updatedFilter));
        setDestinationFilterInStorage(updatedFilter);
    };

    const setDestinationFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            destinationConstants.destinationFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchDestinationData(updatedFilter);
        dispatch(setDestinationFilter(updatedFilter));
        setDestinationFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchDestinationData(filter);
        }
        closeDestinationModal();
    };

    const handleCreateDestination = (): void => {
        if (feedlotId) {
            setSelectedDestination({
                ...DESTINATION_INITIAL_STATE.createDestinationInitial,
                feedLotId: feedlotId,
            });
        }
        openDestinationModal();
    };

    const handleDeleteModalHandler = (destination: IDestinationDetail) => {
        setSelectedDestination(destination);
        openDeleteModal();
    };

    const handleDeleteDestination = (shouldTakeAction: boolean) => {
        if (shouldTakeAction) {
            if (
                !selectedDestination.isAssociated ||
                selectedDestination.active
            ) {
                const isActiveAndAssociated =
                    selectedDestination.isAssociated &&
                    selectedDestination.active;
                const action = isActiveAndAssociated
                    ? updateDestinationDetail
                    : deleteDestination;
                const updatedDestinationDetail = isActiveAndAssociated
                    ? { ...selectedDestination, active: false }
                    : selectedDestination;
                dispatch(
                    action(updatedDestinationDetail, fetchDestinationData),
                );
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const destinationFilter = storage.getValue(
                destinationConstants.destinationFilter,
            );
            if (Object.keys(destinationFilter).length > 0) {
                dispatch(setDestinationFilter(destinationFilter));
            }
            if (!destinationFilter.name) {
                if (destinationFilter.show) {
                    fetchDestinationData(destinationFilter);
                } else {
                    dispatch(
                        fetchDestinationDetails(setDestinationDetails, filter),
                    );
                }
            } else {
                setSearchQuery(destinationFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">
                {destinationConstants.destinationDetails.heading}
            </h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>
                        {destinationConstants.destinationDetails.destination}
                    </label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={
                            destinationConstants.destinationDetails.placeholder
                        }
                        maxLength={
                            destinationConstants.destinationDetails.maxLength
                        }
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {destinationConstants.clearFilter}
                    </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateDestination}>
                        {destinationConstants.createNewDestinations.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<IDestinationDetail>
                columnDetail={destinationConstants.destinationColumnDetail}
                itemDetail={destinationDetails}
                handleEditDetail={handleEditDestinationDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isDestinationModalOpen && (
                <DestinationModal
                    showModal={isDestinationModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editDestinationDetails={selectedDestination}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedDestination}
                    handleDeleteItem={handleDeleteDestination}
                    showModal={isDeleteModalOpen}
                    itemName={destinationConstants.destinationDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default DestinationDetails;
