import { AppDispatch } from '../Store';
import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from './commonActions';
import {
    ICreateYardage,
    IFetchYardageDetails,
} from '../../utils/Interface/YardageInterface';
import { setYardageDetailsLoader } from '../Reducer/yardageSlice';
import { yardageConstants } from '../../utils/constants/YardageConstants';

export const fetchYardageDetails: IFetchYardageDetails =
    (feedlotId, setYardageDetail) => async (dispatch: AppDispatch) => {
        dispatch(setYardageDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_FEEDLOT}${Constants.apiUrls.YARDAGE}/odata?flid=${feedlotId}&$orderby = effectiveDate desc`,
        });
        setYardageDetail(response ?? []);
        dispatch(setYardageDetailsLoader(false));
    };

export const createYardage: ICreateYardage =
    (feedlotId, yardage, closeModalWithChanges, setLoading) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_FEEDLOT}${Constants.apiUrls.YARDAGE}`,
            payload: yardage,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (
            status >= Constants.responseCode.SUCCESS &&
            status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(yardageConstants.toasterMessage));
            closeModalWithChanges(true);
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };
