import { CommonActionTypes } from '.';
import { COMMON_INITIAL_STATE } from '../Constants/commonInitialState';
import Constants from '../../utils/Constants';

export const fetchWindowSize = (
    data = { ...COMMON_INITIAL_STATE.windowSize },
) => {
    return {
        type: CommonActionTypes.FETCH_WINDOW_SIZE,
        payload: data,
    };
};

export const setToggler = (
    data: boolean = COMMON_INITIAL_STATE.isNavActive,
) => {
    return {
        type: CommonActionTypes.SET_ACTIVE,
        payload: data,
    };
};

export const toastMessage = (
    data: any = { ...COMMON_INITIAL_STATE.toastMessage },
) => {
    return {
        type: CommonActionTypes.TOAST_MESSAGE,
        payload: data,
    };
};

export const fetchFeeTypes = (data = [COMMON_INITIAL_STATE.feeTypes]) => {
    return {
        type: CommonActionTypes.FETCH_FEE_TYPES,
        payload: data,
    };
};

export const setSelectedFeedlot = (data = {}) => {
    return {
        type: CommonActionTypes.SET_SELECTED_FEEDLOT,
        payload: data,
    };
};

export const setSelectedLanguage = (language = Constants.Languages[0]) => {
    return {
        type: CommonActionTypes.SET_SELECTED_LANGUAGE,
        payload: language,
    };
};
