import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const feedCallPageSliceInitialState = {
    isIsaacStillWorkingOnThis: true,
};

const feedCallPageSlice = createSlice({
    name: 'feedCallPage',
    initialState: feedCallPageSliceInitialState,
    reducers: {
        setIsIsaacStillWorkingOnThis: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isIsaacStillWorkingOnThis = action.payload;
        },
    },
});

export const feedCallPageActions = feedCallPageSlice.actions;
export const feedCallPageReducer = feedCallPageSlice.reducer;
