import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import {
    IFeedCallInitialState,
    IFeedCallValueLotInformation,
    IFeedCallValueRations,
} from '../../utils/Interface/FeedCallInterface';
import { FeedCallConstants } from '../../utils/constants/FeedCallConstants';

const { isCA9574On_FeedCallDmChangeInsteadAfChange } = feedlotManagerFeatures;

export const FEED_CALL_INITIAL_STATE: IFeedCallInitialState = {
    loading: false,
    feedCallAssessmentLoader: false,
    savePrevNextBtnDisabled: false,
    canCallApi: true,
    estimatedHdCount: '',
    feedCallTotalCall: '',
    bunkScoreApiResponse: false,
    filter: { limit: 20, offset: 0, rationId: 'all' },
    selectedTemplateId: '',
    feedCallPensList: [],
    canShowRationConfirmationModal: false,
    canShowRationChangeReminderModal: false,
    feedCallPenRationSchedule: {
        name: '',
        penName: '',
        penRationScheduleId: -1,
        penRationScheduleTemplateId: 0,
        scheduleType: {
            scheduleTypeId: 0,
            tableName: '',
            type: '',
        },
        splitRations: [],
        startingAFPerHd: 0,
        startingDryMatterPerHd: 0,
    },
    //bunkScoreCurrentValue needs refactor
    bunkScoreCurrentValue: {
        adg: '',
        afChangePerHd: '',
        ...(isCA9574On_FeedCallDmChangeInsteadAfChange
            ? { dmChangePerHd: '' }
            : {}),
        afPerHd: '',
        dmPerHd: '',
        hdCount: '',
        penId: '',
        totalCall: '',
        wtPerDM: '',
        noOfFeedingsBeforeShipment: '',
        estimatedHdCount: '',
    },
    feedCallInfo: {
        feedCall: {
            adg: '',
            afChangePerHd: '',
            ...(isCA9574On_FeedCallDmChangeInsteadAfChange
                ? { dmChangePerHd: '' }
                : {}),
            afPerHd: 0,
            bunkScore: '',
            comments: '',
            createdDateTime: '',
            daysOnCurrentRation: '',
            daysOnFeed: '',
            dmPerHd: '',
            endRation: '',
            estimatedHdCount: '',
            feedCallId: '',
            hayFeeding1: '',
            hayFeeding2: '',
            hdCount: '',
            isScheduledRationChangeValid: false,
            noOfFeedingsBeforeShipment: '',
            pen: null,
            penId: '',
            penRationSchedule: null,
            penRationScheduleId: '',
            startRation: '',
            totalCall: '',
            wtPerDM: '',
        },
        lotInformation: [],
        rations: [],
    },
    originalFeedCallAssessment: {
        adg: '',
        afChangePerHd: '',
        ...(isCA9574On_FeedCallDmChangeInsteadAfChange
            ? { dmChangePerHd: '' }
            : {}),
        afPerHd: '',
        dmPerHd: '',
        hdCount: '',
        penId: '',
        totalCall: '',
        wtPerDM: '',
        noOfFeedingsBeforeShipment: '',
        estimatedHdCount: '',
    },
    originalFeedCallAssessmentPayload: null,
    feedCallSelectedPenId: '',
    feedCallSelectedPenHdCount: 0,
    feedCallCurrentPenIndex: 0,
    feedCallPenRationScheduleId: 0,
    bunkScoreList: [
        {
            bunkScore: 0,
            name: 'Select Bunk',
        },
        {
            bunkScore: 1,
            name: 'Slick',
        },
        {
            bunkScore: 2,
            name: 'Crumbs',
        },
        {
            bunkScore: 3,
            name: '50-100',
        },
        {
            bunkScore: 4,
            name: '100-300',
        },
        {
            bunkScore: 5,
            name: '300-500',
        },
        {
            bunkScore: 6,
            name: '500+',
        },
    ],
    feedCallShippingList: [
        {
            noOfFeedingsBeforeShipment: null,
            name: 'Regular Number of Feedings',
        },
        {
            noOfFeedingsBeforeShipment: 0,
            name: '0',
        },
        {
            noOfFeedingsBeforeShipment: 1,
            name: '1',
        },
    ],
    feedCallNoOfFeedingBeforeShipment: '',
    feedCallTableList: [],
    feedCallEstimatedHeadCount: '',
    totalCallEstimatedHdCountFieldName: '',
    feedCallGraphDays: 'days14',
    feedCallGraphValue: {
        day14: 'days14',
        day30: 'days30',
        day60: 'days60',
    },
    feedCallGraph: [],
    existingEstHeadCountModalLotDetails: [],
    existingEstHeadCountModalLotDetailsCache: [],
    estHeadCountModalLotDetailsCache: [],
    lots: [],
    estHeadCountModalLotDetail: {
        lotName: '',
        lotId: '',
        lotPenId: '',
        headCount: '',
        penId: '',
        estimatedHeadCount: '',
        createdDateTime: '',
    },
    estHeadCountModalLotDetails: [],
    isEstimatedHdCountFieldName: false,
    rations: [
        { name: 'All', id: FeedCallConstants.rationIds.all },
        {
            name: 'CATTLE NO FEED CALL',
            id: FeedCallConstants.rationIds.cattleNoFeedCall,
        },
        { name: 'UNASSIGNED SCHEDULES', id: 0 },
    ],
    isRationFilterChanged: false,
    isScheduledRationChanged: false,
    canShowAfChangeConfirmationModal: false,
    isFeedCallCreated: false,
    isSaveAndNextBtnClicked: false,
    isEstHdCountAvailable: false,
    showPenRationTemplateModal: false,
    isPenRationTemplatedModalClosed: true,
    oldFeedCallTotalCall: 0,
    showFullPageLoader: false,
    isFeedCallTableLoaded: false,
    feedCallLotInformation: [] as IFeedCallValueLotInformation[],
    feedCallRationInformation: [] as IFeedCallValueRations[],
    isButtonsAndFieldsDisabled: false,
};
