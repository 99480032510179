import { useLocation } from 'react-router-dom';
import Constants from '../utils/Constants';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import BreadCrumbs from '../utils/BreadCrumbs';
import OwnerDropdown from './nav/OwnerDropdown';
import FeedlotDropdown from './nav/FeedlotDropdown';
import UserProfileDropdown from './nav/UserProfileDropdown';
import MobileNav from './nav/MobileNav';
import { INav } from '../utils/Interface/NavInterface';
import LanguageDropdown from './nav/LanguageDropdown';

const Nav = ({ sideNavTab }: INav) => {
    const location = useLocation();
    let headerPath = location.pathname.split('/')[1].toLowerCase();
    const { isAuthenticated } = useAuthContext();

    if (['buypos', 'shippos'].includes(headerPath)) {
        headerPath = location.pathname.split('/')[2]?.toLowerCase();
    }

    return !isAuthenticated() ? null : (
        <>
            <div
                id="sidebar"
                className="top-navigation-bar d-flex justify-content-between align-items-center"
            >
                <div>
                    <h5 className="block-heading m-0">
                        {Constants.headerConstants[headerPath] ?? null}
                    </h5>
                </div>
                <div className="d-flex align-items-center">
                    {sideNavTab === 'fm' ? (
                        <>
                            <LanguageDropdown />
                            <FeedlotDropdown />
                        </>
                    ) : sideNavTab === 'financial' &&
                      Constants.showOwnerDropdown.includes(
                          Constants.headerConstants[headerPath],
                      ) ? (
                        <OwnerDropdown />
                    ) : null}
                    <UserProfileDropdown />
                </div>
            </div>
            <MobileNav />
            {headerPath !== 'feedcall' &&
                headerPath !== 'feedcallv2' &&
                headerPath !== 'yardlayout' &&
                headerPath !== 'calendar' && <BreadCrumbs />}
        </>
    );
};

export default Nav;
