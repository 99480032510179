import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate_deprecated } from '../../helpers';
import { LotStateEnum } from '../../utils/Interface/LotInterface';
import {
    IFee,
    IFeeError,
    IReceiveCattleDetail,
    IReceiveCattleDetailError,
    IReceiveCattleFilter,
    IReceiveCattleLot,
    IReceiveCattlePen,
    IReceiveCattlePenError,
} from '../../utils/Interface/ReceiveCattleInterface';
import {
    LabelConstant,
    receiveCattleLabelConstants,
} from '../../utils/LabelConstant';
import { CommonConstants } from '../../utils/constants/CommonConstants';

const { isCA4879On_CloseALot } = feedlotManagerFeatures;

/**
 * Create Receive Cattle initial states
 */
export const CREATE_RECEIVE_CATTLE_INITIAL_STATE = {
    receiveCattleDetail: {
        receiveId: '',
        dateReceived: currentDate_deprecated('YYYY-MM-DD'),
        hdCount: '',
        ownerId: '',
        notes: '',
        lotId: '',
        lot: {} as IReceiveCattleLot,
        assignedPens: [] as IReceiveCattlePen[],
        buyerId: '',
        buyLocationId: '',
        offTruckWeight: '',
        payWeight: '',
        cattleTypeId: '',
        sexId: '',
        status: false,
    } as IReceiveCattleDetail,
    receiveCattleDetailError: {
        dateReceived: '',
        hdCount: '',
        ownerId: '',
        lotId: '',
        buyLocationId: '',
        buyerId: '',
        offTruckWeight: '',
        cattleTypeId: '',
        sexId: '',
    } as IReceiveCattleDetailError,
    receiveCattleAssignPens: [
        { penId: '', hdCount: '' },
    ] as IReceiveCattlePen[],
    receiveCattleAssignPensError: [
        { penId: false, hdCount: false },
    ] as IReceiveCattlePenError[],
    receiveCattleFees: [
        { id: '', costs: 'Cattle', amount: '', notes: '', isDisable: true },
        { id: '', costs: 'Freight', amount: '', notes: '', isDisable: true },
    ] as IFee[],
    receiveCattleFeesInitialState: [
        { id: '', costs: 'Cattle', amount: '', notes: '', isDisable: true },
        { id: '', costs: 'Freight', amount: '', notes: '', isDisable: true },
    ] as IFee[],
    prefilledFeeTypes: ['Cattle', 'Freight'],
    receiveCattleFeesError: [{ costs: '' }, { costs: '' }] as IFeeError[],
    receiveCattleFee: {
        id: '',
        costs: '',
        amount: '',
        notes: '',
        isDisable: false,
    } as IFee,
    receiveCattleFeeError: { costs: '' } as IFeeError,
    feeTotalAmount: 0,
    generatedInvoiceCutoffDate: '',
    FEE_TYPES: [
        {
            name: LabelConstant.FeeType.SELECT_FEE_TYPE,
            value: LabelConstant.FeeType.SELECT_FEE_TYPE,
        },
        {
            name: LabelConstant.FeeType.TYPE_CATTLE,
            value: LabelConstant.FeeType.TYPE_CATTLE,
        },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
    ],
    RECEIVE_CATTLE_STATIC_PEN: {
        penId: '',
        hdCount: '',
    } as IReceiveCattlePen,
    RECEIVE_CATTLE_STATIC_PEN_ERROR: {
        penId: false,
        hdCount: false,
    } as IReceiveCattlePenError,
    OWNERS: [
        {
            name: receiveCattleLabelConstants.Owner.CREATE_OWNER,
            id: receiveCattleLabelConstants.Owner.CREATE_OWNER,
        },
    ],
    LOTS: [
        {
            name: receiveCattleLabelConstants.Lot.CREATE_LOT,
            lotId: receiveCattleLabelConstants.Lot.CREATE_LOT,
        },
    ] as IReceiveCattleLot[],
    PENS: [] as IReceiveCattlePen[],
    BUYERS: [
        {
            name: receiveCattleLabelConstants.Buyer.CREATE_BUYER,
            id: receiveCattleLabelConstants.Buyer.CREATE_BUYER,
        },
    ],
    BUY_LOCATIONS: [
        {
            name: receiveCattleLabelConstants.BuyLocation.CREATE_BUYER_LOCATION,
            id: receiveCattleLabelConstants.BuyLocation.CREATE_BUYER_LOCATION,
        },
    ],
    CATTLE_TYPES: [
        {
            name: receiveCattleLabelConstants.CattleType.SELECT_CATTLE_TYPE,
            id: '',
        },
    ],
    CATTLE_SEX: [
        {
            name: receiveCattleLabelConstants.CattleSex.SELECT_CATTLE_SEX,
            id: '',
        },
    ],
    FM_FEEDLOT: [
        { name: receiveCattleLabelConstants.Feedlot.SELECT_FEEDLOT, id: '' },
    ],
    lots: [] as IReceiveCattleLot[],
    loading: false,

    filter: {
        lotId: '',
        ownerId: '',
        complete: '',
        buyLocationId: '',
        hdCount: '',
        receiveId: '',
        toDate: '',
        fromDate: '',
        top: 20,
        skip: 0,
        state: isCA4879On_CloseALot
            ? [`'${LotStateEnum.Open}'`, `'${LotStateEnum.Closing}'`]
            : [
                  `'${CommonConstants.lotState.Open}'`,
                  `'${CommonConstants.lotState.Closing}'`,
              ],
    } as IReceiveCattleFilter,
    owners: [],
    origins: [],
    receiveCattleReports: [],
};
