import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP786On_orderSexDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP778_ORDER_SEX_DROPDOWN,
    ),
    isBP778On_orderBreedDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP778_ORDER_BREED_DROPDOWN,
    ),
    isBP787On_orderColorDropdown: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP787_ORDER_COLOR_DROPDOWN,
    ),
    isBP828On_inconsistentLogoBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP828_INCONSISTENT_LOGO_BUG,
    ),
    isBP804On_validateMandatoryFields: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP804_VALIDATE_MANDATORY_FIELDS,
    ),
    isBP796On_ccEmailSubmitter: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP796_CC_EMAIL_SUBMITTER,
    ),
    isBP799On_addCanadaDestination: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP799_ADD_CANADA_DESTINATION,
    ),
    isBP798On_addCanadaLocation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP799_ADD_CANADA_LOCATION,
    ),
    isBP862On_termSlideUnit: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP862_TERM_SLIDE_UNIT,
    ),
    isBP860On_buyPOPickupDateRange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP860_BUYPO_PICKUP_DATE_RANGE,
    ),
};
