import auth0, {
    Auth0DecodedHash,
    Auth0Error,
    Auth0ParseHashError,
} from 'auth0-js';
import { jwtDecode } from 'jwt-decode';
import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Buffer } from 'buffer';
import { Roles } from '../../../../../utils/constants/RoleConstants';
import { toast } from 'react-toastify';
import {
    IAuth,
    IJwtPayload,
    ILocalAuthResponse,
} from '../../../../../utils/Interface/AuthInterface';
import { EnvRoles, TOKEN } from '../constants/AuthConstants';
import { INavFeedlot } from '../../../../../utils/Interface/NavInterface';
import dayjs from '../../../../../utils/dayjs';
import { apiCall_v2, setBasic } from '../../../../../Services/AxiosService';
import Constants from '../../../../../utils/Constants';

const REDIRECT_ON_LOGIN = 'redirect_on_login';
const LOCAL_AUTH_STORAGE_KEY = 'ngat-auth';

// Stored outside class since private
let _accessToken: string | null = null;
let _decodedAccessToken: IJwtPayload | null = null;
let _expiresAt = 0;

export default function AuthWrapper() {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();
    return new Auth(navigate, location);
}

class Auth implements IAuth {
    navigate: NavigateFunction;
    location: Location;
    auth0: auth0.WebAuth;

    constructor(navigate: NavigateFunction, location: Location) {
        this.navigate = navigate;
        this.location = location;
        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
            redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            responseType: 'token id_token',
        });
    }

    login = () => {
        localStorage.setItem(REDIRECT_ON_LOGIN, JSON.stringify(this.location));
        this.auth0.authorize();
    };

    logout = () => {
        this.auth0.logout({
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            returnTo: process.env.REACT_APP_LOGOUT_REDIRECT,
        });
        localStorage.clear();
    };

    handleAuthentication = () => {
        this.auth0.parseHash(
            (
                err: Auth0ParseHashError | null,
                authResult: Auth0DecodedHash | null,
            ) => {
                if (authResult) {
                    this.setSession(authResult);
                    const redirectLocation =
                        localStorage.getItem(REDIRECT_ON_LOGIN) === 'undefined'
                            ? '/'
                            : JSON.parse(
                                  localStorage.getItem(REDIRECT_ON_LOGIN) || '',
                              );
                    this.navigate(redirectLocation);
                } else if (err) {
                    this.navigate('/');
                    toast.error(
                        `Error: ${err.error}. Check the console for further details.`,
                        {
                            position: 'top-right',
                            autoClose: 15000,
                        },
                    );

                    console.error(err);
                }
                localStorage.removeItem(REDIRECT_ON_LOGIN);
            },
        );
    };

    setSession = (authResult: Auth0DecodedHash) => {
        if (authResult.expiresIn && authResult.accessToken) {
            _expiresAt = authResult.expiresIn * 1000 + dayjs().valueOf();
            _accessToken = authResult.accessToken;
            _decodedAccessToken = jwtDecode<IJwtPayload>(_accessToken || '');
            this.scheduleTokenRenewal();
        }
    };

    isAuthenticated = (excludeTransientToken = true): boolean => {
        if (excludeTransientToken) {
            return !this.isTransientToken() && dayjs().valueOf() < _expiresAt;
        } else {
            return dayjs().valueOf() < _expiresAt;
        }
    };

    getAccessToken = () => {
        if (!_accessToken) {
            throw new Error('No access token found.');
        }
        return _accessToken;
    };

    getDecodedToken = (): IJwtPayload | null => _decodedAccessToken;

    saveLocalToken = auth => {
        localStorage.setItem(
            LOCAL_AUTH_STORAGE_KEY,
            Buffer.from(auth).toString('base64'),
        );
    };

    localAuthLogin = async ({
        username,
        password,
        redirectPath,
        isTransient = false,
    }: {
        username: string;
        password: string;
        redirectPath: string;
        isTransient: boolean;
    }) => {
        setBasic(username, password);
        const res = await apiCall_v2({
            method: 'get',
            url: isTransient
                ? Constants.apiUrls.TRANSIENT_USER_AUTH
                : Constants.apiUrls.USER_AUTH,
            isResRequired: true,
            showBackendErrorToast: true,
        });
        if (res?.status === Constants.responseCode.SUCCESS) {
            const authres = res.data as ILocalAuthResponse;
            this.saveLocalToken(JSON.stringify(authres));
            this.setSession({
                expiresIn:
                    dayjs(authres.tokenExpiry).valueOf() - dayjs().valueOf(),
                accessToken: authres.token,
            });
            if (redirectPath) {
                this.navigate(redirectPath);
            }
        }
    };

    renewLocalToken = () => {
        const ngatAuth = localStorage.getItem(LOCAL_AUTH_STORAGE_KEY);
        if (ngatAuth) {
            const json = Buffer.from(ngatAuth, 'base64').toString('utf-8');
            const authl: ILocalAuthResponse = JSON.parse(json);
            if (dayjs(authl.tokenExpiry).valueOf() - dayjs().valueOf() > 0) {
                const token = {
                    expiresIn:
                        dayjs(authl.tokenExpiry).valueOf() - dayjs().valueOf(),
                    accessToken: authl.token,
                };
                this.setSession(token);

                return JSON.stringify(token);
            }
        }

        return null;
    };

    renewToken = (
        cb:
            | ((
                  err: Auth0Error | null,
                  result: Auth0DecodedHash | null,
              ) => void)
            | null,
    ) => {
        const token = this.renewLocalToken();
        if (token) {
            if (cb) cb(null, null);
        } else {
            this.auth0.checkSession(
                {},
                (err: Auth0Error | null, result: Auth0DecodedHash) => {
                    if (err) {
                        if (this.isAuthenticated()) {
                            console.error(
                                `Error: ${err.error} - ${err.error_description}.`,
                            );
                        }
                    } else {
                        this.setSession(result);
                    }
                    if (cb) cb(err, result);
                },
            );
        }
    };

    scheduleTokenRenewal = () => {
        const delay = _expiresAt - dayjs().valueOf();
        const reducedDelayToEnsureTokenValidWhenRenewed = delay / 2;
        if (delay > 0)
            setTimeout(
                () => this.renewToken(null),
                reducedDelayToEnsureTokenValidWhenRenewed,
            );
    };

    getTenantId = (): string => getJwtPropertyValue(TOKEN.TID_FIELD);

    getUserEmail = (): string => getJwtPropertyValue(TOKEN.EMAILADDRESS_FIELD);

    getUserName = (): string => getJwtPropertyValue(TOKEN.NAME_FIELD);

    getUsersRoles = (): string[] => getJwtPropertyValues(TOKEN.ROLE_FIELD);

    getUsersActors = (): string[] => getJwtPropertyValues(TOKEN.ACTOR_FIELD);

    getUsersEnvs = (): string[] => getJwtPropertyValues(TOKEN.ENV_FIELD);

    getUsersAppSubscriptions = (): string[] =>
        getJwtPropertyValues(TOKEN.APP_FIELD);

    getUsersFeedlots = (allFeedlots: INavFeedlot[]): INavFeedlot[] => {
        let usersFeedlots: INavFeedlot[] = [];
        const usersRoles: string[] = this.getUsersRoles();
        const usersActors: string[] = this.getUsersActors();

        if (
            (usersRoles.includes(Roles.ADMIN) ||
                usersRoles.includes(Roles.OFFICE_MANAGER)) &&
            usersActors.length === 0
        ) {
            usersFeedlots = allFeedlots;
        } else {
            usersFeedlots = allFeedlots.filter(feedlot =>
                usersActors.includes(feedlot.label),
            );
        }
        return usersFeedlots;
    };

    accessHandler = (designatedRoles: string[]): boolean => {
        const usersRoles: string[] = this.getUsersRoles();
        for (
            let roleIndex = 0;
            roleIndex <= designatedRoles.length;
            roleIndex++
        ) {
            const canAccess = usersRoles.includes(designatedRoles[roleIndex]);

            if (canAccess) {
                return true;
            }
        }
        return false;
    };

    hasSubscriptionServiceAccess = () => {
        const paymentServiceStatus =
            process.env.REACT_APP_SUBSCRIPTIONS_SERVICE === 'on';

        return paymentServiceStatus;
    };

    hasWarehousingAppAccess = () => {
        return this.accessHandler([Roles.WAREHOUSING_APP]);
    };

    hasTenantId = (): boolean => !!getJwtPropertyValue(TOKEN.TID_FIELD);

    hasSubscriptions = (): boolean => {
        return this.getUsersAppSubscriptions().length > 0;
    };

    hasBetaRestriction = (): boolean =>
        this.getUsersEnvs().includes(EnvRoles.Beta);

    hasDevRestriction = (): boolean =>
        this.getUsersEnvs().includes(EnvRoles.Development);

    isTransientToken = (): boolean =>
        getJwtPropertyValues('scope').includes('TransientAuthentication');
}

const getJwtPropertyValues = (property: string): string[] => {
    const abbrprop = () => {
        return property === TOKEN.EMAILADDRESS_FIELD
            ? TOKEN.EMAIL_FIELD
            : property.substring(property.lastIndexOf('/') + 1);
    };

    let jwtPropertyValues: string | string[] =
        _decodedAccessToken?.[property] ??
        _decodedAccessToken?.[abbrprop()] ??
        [];

    if (typeof jwtPropertyValues === 'string') {
        jwtPropertyValues = [jwtPropertyValues];
    }
    return jwtPropertyValues;
};

const getJwtPropertyValue = (property: string): string =>
    getJwtPropertyValues(property)?.[0] ?? '';
