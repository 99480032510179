const FinancialRoutes = {
    Api: {
        CUSTOMERS: '/customers',
        CATTLE_FAXES: '/cattlefax',
        FEEDBILLS: '/feedBills',
        FEEDLOTS: '/feedlots',
        HEDGE_CONTRACTS: '/hedgeContracts',
        HEDGE_PURCHASES: '/hedgePurchases',
        HEDGE_SALES: '/hedgeSales',
        INTEREST_RATE_CHANGES: '/interestRateChanges',
        LOT_ACTIONS: '/lotActions',
        LOTS: '/lots',
        RE_LOTS: '/relots',
        SETTINGS: '/settings',
        STATEMENT: '/statement',
        PROJECT_BREAKEVEN_REPORT: 'Lots/reports/breakeven',
    },

    App: {
        HEDGES: '/hedges',
        EDIT_HEDGE: '/editHedge',
        EDIT_HEDGE_WITH_PARAM: '/editHedge/:hedgeId',
        CREATE_OPEN_HEDGE: '/createOpenHedge',
        CREATE_CLOSE_HEDGE: '/createCloseHedge',

        CREATE_OWNER: '/createOwner',

        CUSTOMER_CLOSEOUT: '/customerCloseout',
        CUSTOMER_CLOSEOUT_WITH_PARAM: '/customerCloseout/:lotId',
        LENDER_REPORT: '/lenderReport',
        OWNER_REPORT: '/ownerReport',
        SHAWNA_SPREADSHEET: '/lotCashFlow',

        FEEDLOTS: '/feedlots',
        CREATE_FEEDLOT: '/createFeedlot',
        FEEDLOT: '/feedlot',
        FEEDLOT_WITH_PARAM: '/feedlot/:feedlotId',

        LOTS: '/lots',
        CREATE_LOT: '/createLot',
        LOT: '/lot',
        LOT_WITH_PARAM: '/lot/:lotId',
        LOT_BALANCE_REPORT: '/lots/lotBalancesReport',
        LOT_SUMMARY_REPORT: '/lots/lotSummaryReport',
        QUARTERLY_REPORT: '/lots/quarterlyReport',
        PROJECT_BREAKEVEN_REPORT: '/lots/projectedbreakevenreport',

        CREATE_RE_LOT: '/createReLot',
        EDIT_RE_LOT: '/editReLot',
        EDIT_RE_LOT_WITH_PARAM: '/editReLot/:reLotId',

        FEEDBILLS: '/feedBills',
        CREATE_FEEDBILL: '/createFeedBill',
        FEEDBILL: '/feedBill',
        EDIT_FEEDBILL: '/editFeedBill',
        EDIT_FEEDBILL_WITH_PARAM: '/editFeedBill/:feedBillId',

        CREATE_CATTLE_PURCHASE: '/createCattlePurchase',
        EDIT_CATTLE_PURCHASE: '/editCattlePurchase',
        EDIT_CATTLE_PURCHASE_WITH_PARAM:
            '/editCattlePurchase/:cattlePurchaseId',

        CREATE_CATTLE_SALE: '/createCattleSale',
        EDIT_CATTLE_SALE: '/editCattleSale',
        EDIT_CATTLE_SALE_WITH_PARAM: '/editCattleSale/:cattleSaleId',

        CREATE_CATTLE_WEIGHT: '/createCattleWeight',
        EDIT_CATTLE_WEIGHT: '/editCattleWeight',
        EDIT_CATTLE_WEIGHT_WITH_PARAM: '/editCattleWeight/:cattleWeightId',

        CREATE_CATTLE_DEAD: '/createCattleDead',
        EDIT_CATTLE_DEAD: '/editCattleDead',
        EDIT_CATTLE_DEAD_WITH_PARAM: '/editCattleDead/:cattleDeadId',

        HEDGE_SALE: '/hedgeSales',
        HEDGE_CONTRACTS: '/hedgecontracts',

        STATEMENTS: '/statements',

        CATTLE_FAXES: '/cattlefaxes',

        INTEREST_RATE_CHANGES: '/interestRateChanges',

        SETTINGS: '/settings',
    },
};

export default FinancialRoutes;
