import { ICattleType, IFMFeedlot, IOwner, IPen } from './FeedlotInterface';
import { ISex } from './TreatmentsInterface';

export interface ClosedLot {
    closedLots: any;
}

export interface IMovementHistoryItem {
    date: string;
    hdCount: number;
    location: string;
    payWeight: number;
    status: boolean;
    tableId: number;
    tableName: string;
    cumulativeHeadCount?: number;
}

export interface IHeadCountHistoryItem {
    date: string;
    hdCount: number;
    location: string;
    payWeight: number;
    status: boolean;
    tableId: number;
    tableName: HeadCountHistoryRecordType;
    cumulativeHeadCount: number;
}

export interface HC {
    hc: any;
}

export interface HCT {
    lot: any;
}

export interface LotAct {
    lot: any;
    la: any;
    index: any;
}

export interface LotFilter {
    lotActionsFilterProps: any;
}

export interface LotBalance {
    reportData: any;
    totalLotsBalance: any;
}

export interface LotDetail {
    _lot: any;
}

export interface LotTabl {
    _filteredLots: any;
}
export interface LotSummaryDetailsInterface {
    lotFilter: any;
    filterLotHandler: any;
    lots: any;
    loading: any;
}

export interface ITotals {
    totalLot: IGeneralSummaryModel;
}

export interface IGeneralSummaryModel {
    total: ITotalModel;
    average: IAverageModel;
    currentHeadCount: ICurrentHeadcountModel;
    lotInfo?: ILotInfo;
    penCurrentHeadCount: IPenCurrentHeadCount[];
}

export interface ILotInfo {
    lotId: number;
    lotName: string;
    ownerId: number;
    ownerName: string;
    sexId: number;
    sex: string;
}

type IAverageModel = {
    payWeightPerHead?: number;
    averageDateIn?: string;
    averageCostInPerLb?: number;
    averageDayOnFeed?: number;
    averageDailyGain: number;
    estimatedCurrentWeight?: number;
};

type ITotalModel = {
    totalCattleCost: number;
    totalCost: number;
    totalPayWeight: number;
};

type ICurrentHeadcountModel = {
    purchased?: number;
    sold?: number;
    dead?: number;
    realizers?: number;
    remaining?: number;
};

export interface IPenCurrentHeadCount {
    pen: string;
    penId: number;
    hdCount: number;
    currentWeight?: number;
    checkWeight?: number;
}

export interface ICheckWeightPayload {
    checkWeight: number;
}

export interface IFMLotUpdateModel {
    lotId?: number;
    name?: string;
    hdCount?: number;
    hdCountReprocessed?: number;
    hdCountProcessed?: number;
    averageDailyGain?: number | string;
    feedLotId?: number;
    ownerId?: number;
    cattleTypeId?: number;
    sex?: ISex;
    sexId?: number;
    createdDatetime?: string;
    state?: string;
}

export interface IFMLot {
    lotId: number;
    name: string;
    hdCount: number;
    totalHdCount: number;
    hdCountReprocessed: number;
    hdCountProcessed: number;
    lotAverageWeight: number;
    averageDailyGain: number;
    feedlot?: IFMFeedlot;
    feedLotId: number;
    owner?: IOwner;
    ownerId: number;
    cattleType?: ICattleType;
    cattleTypeId: number;
    sex?: ISex;
    sexId: number;
    pens?: IPen;
    createdDatetime: string;
    state: string;
}

export enum LotStateEnum {
    Open = 'Open',
    Closing = 'Closing',
    Inactive = 'Inactive',
}

export enum HeadCountHistoryRecordType {
    mortalitys = 'Mortalitys',
    realizerShipment = 'RealizerShipments',
    shipCattle = 'ShipCattle',
    receiveCattle = 'ReceiveCattle',
}
