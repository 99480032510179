import { configureStore } from '@reduxjs/toolkit';
//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from '../Reducer/index';
import { rootApi } from '../Apis/rootApi';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(rootApi.middleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export type GetState = () => RootState;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
