export const TENANT_SETTINGS_INITIAL_STATE = {
    canAccessKillGroup: false,
    loading: false,
    filter: {
        destinationFilter: {
            show: 'active',
            name: '',
        },
        buyLocationFilter: {
            show: 'active',
            name: '',
        },
        truckingCompanyFilter: {
            show: 'active',
            name: '',
        },
        killGroupFilter: {
            show: 'active',
            name: '',
        },
    },
};
