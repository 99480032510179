import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { TENANT_SETTINGS_INITIAL_STATE } from '../Constants/TenantSettingsInitialState';

export const tenantSettingsReducer = (
    state = TENANT_SETTINGS_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_KILL_GROUP_VISIBILITY:
            return {
                ...state,
                canAccessKillGroup: action.payload,
            };

        case ActionTypes.SET_TENANT_SETTINGS_FILTER:
            return {
                ...state,
                filter: action.payload,
            };
        case ActionTypes.APPEND_TENANT_SETTINGS_FILTER:
            return {
                ...state,
                filter: { ...state.filter, ...action.payload },
            };
        case ActionTypes.SET_TENANT_SETTINGS_LOADER:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
