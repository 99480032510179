import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IOverlayTooltip } from '../../utils/Interface/CommonInterface';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';

const { isCA9919On_ManageUsersPage } = feedlotManagerFeatures;
const { isCA10135On_NullTooltipBug } = commonFeatures;

const OverlayTooltip = ({
    tooltipValue,
    endStringLength,
    startStrLength = 0,
}: IOverlayTooltip) => {
    const lengthIsValid = isCA10135On_NullTooltipBug
        ? tooltipValue?.length > endStringLength
        : tooltipValue.length > endStringLength;
    return (
        <>
            {lengthIsValid ? (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{tooltipValue}</Tooltip>}
                >
                    <div
                        className={
                            isCA9919On_ManageUsersPage ? '' : 'tool-tip-title'
                        }
                    >
                        {`${tooltipValue?.slice(
                            startStrLength,
                            endStringLength,
                        )}...`}
                    </div>
                </OverlayTrigger>
            ) : (
                <>{tooltipValue}</>
            )}
        </>
    );
};

export default OverlayTooltip;
