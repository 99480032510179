import { currentDate_deprecated } from '../../helpers';
import { IMoveCattleInitialState } from '../../utils/Interface/MoveCattleInterface';
import { receiveCattleLabelConstants } from '../../utils/LabelConstant';

export const CREATE_MOVE_CATTLE_INITIAL_STATE: IMoveCattleInitialState = {
    moveCattleBasicDetail: {
        id: 0,
        date: currentDate_deprecated('YYYY-MM-DD'),
        lotId: 0,
        lotHdCount: 0,
        comment: '',
    },
    moveCattleBasicDetailError: { date: false, lotId: false },
    fromPens: [],
    toPens: [{ toPenId: 0, showEmpty: true, toMove: '', kind: '' }],
    toPen: { toPenId: 0, showEmpty: true, toMove: '', kind: '' },
    pens: [],
    lots: [],
    movementHistory: [],
    selectLot: { name: receiveCattleLabelConstants.Lot.SELECT_LOT, id: 0 },
    selectPen: {
        name: receiveCattleLabelConstants.Pen.SELECT_PEN,
        id: 0,
        kind: '',
    },
    isDisable: false,
    isHistoricRecord: false,
    isMovementSubmitPending: false,
};
