/* eslint-disable @typescript-eslint/no-explicit-any*/
import { useEffect, useState } from 'react';

import { getQueryString } from '../helpers';
import { apiCall, apiCall_v2 } from '../Services/AxiosService';
import FinancialRoutes from './constants/FinancialApp/FinancialRoutes';

import { ICattleFax } from '../apps/financeManager/modules/cattleFax/interfaces/CattleFaxInterface';
import { ICustomer } from './Interface/FinancialApp/CustomerInterface';
import { IFeedBill } from '../apps/financeManager/modules/feedbill/interfaces/FeedBillInterface';
import { IFeedlot } from '../apps/financeManager/modules/Feedlots/Interfaces/FeedLotInterface';
import { IInterestRateChange } from '../apps/financeManager/modules/interestRates/interfaces/InterestRateChangesInterface';
import { ILot } from './Interface/FinancialApp/LotInterface';
import {
    IHistoricalSettingEntry,
    INonHistoricalSettingEntry,
    ISettings,
} from '../apps/financeManager/modules/settings/interfaces/SettingInterface';
import { IStatement } from './Interface/FinancialApp/StatementInterface';
import { IReLotResponse } from './Interface/FinancialApp/ReLotInterface';
import Constants from './Constants';
import { financeManagerFeatures } from '../apps/financeManager/featureFlags/financeManagerFeatures';

const isFA504On_QuarterlyReportLotsIssue = { financeManagerFeatures };

// hooks for getting lists of records
function useGenericGetHook<T>(
    url: string,
    params: { [key: string]: number | string },
    dependencies: any[],
    canCallApi = true,
    beforeFetchCallback?: () => void,
    afterFetchCallback?: () => void,
): T | undefined {
    const [state, setState] = useState<T>();
    const queryString = getQueryString(params);

    const FA504_dependencyArray = isFA504On_QuarterlyReportLotsIssue
        ? [
              ...dependencies,
              url,
              queryString,
              canCallApi,
              beforeFetchCallback,
              afterFetchCallback,
          ]
        : [...dependencies, url, queryString];

    useEffect(() => {
        if (canCallApi) {
            if (isFA504On_QuarterlyReportLotsIssue) {
                const getAndSetState = async () => {
                    beforeFetchCallback && beforeFetchCallback();

                    const data: T = await apiCall_v2({
                        method: 'get',
                        url: `${url}?${queryString}`,
                    });
                    setState(data);

                    afterFetchCallback && afterFetchCallback();
                };
                getAndSetState();
            } else {
                const getAndSetState = async () => {
                    const data: T = await apiCall(
                        'get',
                        `${url}?${queryString}`,
                    );
                    setState(data);
                };
                getAndSetState();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, FA504_dependencyArray);
    return state;
}

export const useCattleFaxes = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<ICattleFax[]>(
        FinancialRoutes.Api.CATTLE_FAXES,
        params,
        dependencies,
    );

export const useCustomers = (
    params = {},
    dependencies: any[] = [],
    canAccessCustomer?: boolean,
) =>
    useGenericGetHook<ICustomer[]>(
        FinancialRoutes.Api.CUSTOMERS,
        params,
        dependencies,
        canAccessCustomer,
    );

export const useInterestRateChanges = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<IInterestRateChange[]>(
        FinancialRoutes.Api.INTEREST_RATE_CHANGES,
        params,
        dependencies,
    );

export const useFeedBills = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<IFeedBill[]>(
        FinancialRoutes.Api.FEEDBILLS,
        params,
        dependencies,
    );

export const useFeedlots = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<IFeedlot[]>(
        FinancialRoutes.Api.FEEDLOTS,
        params,
        dependencies,
    );

export const useLots = (
    params = {},
    dependencies: any[] = [],
    beforeFetchCallback?: () => void,
    afterFetchCallback?: () => void,
) =>
    useGenericGetHook<ILot[]>(
        `${FinancialRoutes.Api.LOTS}${Constants.apiUrls.ODATA}`,
        params,
        dependencies,
        undefined,
        beforeFetchCallback,
        afterFetchCallback,
    );

export const useReLots = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<IReLotResponse[]>(
        FinancialRoutes.Api.RE_LOTS,
        params,
        dependencies,
    );

export const useSettings = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<ISettings>(
        FinancialRoutes.Api.SETTINGS,
        params,
        dependencies,
    );

export const useStatements = (params = {}, dependencies: any[] = []) =>
    useGenericGetHook<IStatement[]>(
        FinancialRoutes.Api.STATEMENT,
        params,
        dependencies,
    );

// hooks for getting single records
function useGenericGetByIdHook<T>(
    url: string,
    recordId: string,
    params: { [key: string]: number | string },
    dependencies: any[],
): T | undefined {
    const [state, setState] = useState<T>();
    const queryString = getQueryString(params);
    useEffect(() => {
        const getAndSetState = async () => {
            if (recordId) {
                const data: T = await apiCall(
                    'get',
                    `${url}/${recordId}?${queryString}`,
                );
                setState(data);
            }
        };
        getAndSetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, url, recordId, queryString]);
    return state;
}

export const useCattleFax = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<ICattleFax>(
        FinancialRoutes.Api.CATTLE_FAXES,
        recordId,
        params,
        dependencies,
    );

export const useCustomer = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<ICustomer>(
        FinancialRoutes.Api.CUSTOMERS,
        recordId,
        params,
        dependencies,
    );

export const useInterestRateChange = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<IInterestRateChange>(
        FinancialRoutes.Api.INTEREST_RATE_CHANGES,
        recordId,
        params,
        dependencies,
    );

export const useFeedBill = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<IFeedBill>(
        FinancialRoutes.Api.FEEDBILLS,
        recordId,
        params,
        dependencies,
    );

export const useFeedlot = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<IFeedlot>(
        FinancialRoutes.Api.FEEDLOTS,
        recordId,
        params,
        dependencies,
    );

export const useLot = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<ILot>(
        FinancialRoutes.Api.LOTS,
        recordId,
        params,
        dependencies,
    );

export const useSetting = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<IHistoricalSettingEntry | INonHistoricalSettingEntry>(
        FinancialRoutes.Api.SETTINGS,
        recordId,
        params,
        dependencies,
    );

export const useStatement = (
    recordId: string,
    params = {},
    dependencies: any[] = [],
) =>
    useGenericGetByIdHook<IStatement>(
        FinancialRoutes.Api.STATEMENT,
        recordId,
        params,
        dependencies,
    );
