import { createSlice } from '@reduxjs/toolkit';
import { PROCESSING_LOT_INITIAL_STATE } from '../Constants/processingLotInitialState';

const processingLotSlice = createSlice({
    name: 'processingLot',
    initialState: PROCESSING_LOT_INITIAL_STATE,
    reducers: {
        setAllProcessingLots: (state, action) => {
            state.lots = action.payload;
        },
        setLotSummaryLot: (state, action) => {
            state.lotSummaryLot = action.payload;
        },
        setProcessingLot: (state, action) => {
            state.lot = action.payload;
        },
        updateOneLotWorksets: (state, action) => {
            const foundLot = state.lots.find(
                lot => lot.lotId === action.payload.lotId,
            );
            if (foundLot) {
                const newWorksets = action.payload.processingWorksets;
                foundLot.processingWorksets = newWorksets;
            }
        },
        setProcessingLotSubmitPending: (state, action) => {
            state.submitPending = action.payload;
        },
    },
});

export const processingLotActions = processingLotSlice.actions;
export const processingLotReducer = processingLotSlice.reducer;
