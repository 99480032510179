import { Modal, Button } from 'react-bootstrap';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { IConfirmationModal } from '../../utils/Interface/CommonInterface';
import { useLanguage } from '../../commonHooks/useLocalization';

const ConfirmationModal = ({
    showModal,
    confirmationModalHandler,
    confirmationMessage,
    cancelLabel,
    confirmLabel,
}: IConfirmationModal) => {
    const language = useLanguage();
    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={showModal}
            centered={true}
            animation={true}
            onHide={() => confirmationModalHandler(false)}
        >
            <Modal.Body>
                <Modal.Title className="text-secondary">
                    {confirmationMessage}
                </Modal.Title>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => confirmationModalHandler(false)}
                >
                    {cancelLabel ?? CommonConstants[language.value].no}
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => confirmationModalHandler(true)}
                >
                    {confirmLabel ?? CommonConstants[language.value].yes}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
