import { apiCall, apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateTruck,
    IDeleteTruck,
    IFetchTruckDetails,
    IFilter,
    IUpdateTruckDetail,
} from '../../utils/Interface/TruckInterface';
import { getToastContent } from '../../utils/ToastContent';
import { setTruckDetailsLoader } from '../Reducer/truckReducer';
import { AppDispatch } from '../Store';
import { toastMessage } from './commonActions';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchTruckDetails: IFetchTruckDetails =
    (feedlotId, setTruckDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setTruckDetailsLoader(true));
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }
        const response = await apiCall(
            'get',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.FEED_TRUCK}/odata?flid=${feedlotId}${filterUrl}`,
        );
        setTruckDetail(response);
        dispatch(setTruckDetailsLoader(false));
    };

export const updateTruckDetail: IUpdateTruckDetail =
    (
        feedlotId,
        truckDetail,
        fetchTruckData?,
        closeModalWithChanges?,
        setLoading?,
        handleActivateTruck?,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.FEED_TRUCK}/${truckDetail.truckId}`,
            payload: truckDetail,
            isResRequired: true,
        });

        setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.TRUCK_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTruck?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            closeModalWithChanges?.(true);
        }
        fetchTruckData?.();
    };

export const createTruck: ICreateTruck =
    (
        feedlotId,
        truck,
        closeModalWithChanges,
        setLoading,
        handleActivateTruck,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.FEED_TRUCK}`,
            payload: truck,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.TRUCK_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTruck();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true);
        }
    };

export const fetchInactiveTruckInfo =
    (feedlotId: number, truck?: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.FEED_TRUCK}/odata?filter=name eq '${truck}'`,
            isResRequired: true,
        });
        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteTruck: IDeleteTruck =
    (feedlotId, truck, fetchTruckData?) => async (dispatch: AppDispatch) => {
        dispatch(setTruckDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.FEED_TRUCK}/${truck.truckId}`,
            payload: truck,
            isResRequired: true,
        });
        dispatch(setTruckDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchTruckData?.();
    };
