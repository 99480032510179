import { BuyPoFeesConstants } from './BuyPoConstants';
import {
    IBuyPoFee,
    IBuyPoFilter,
    IBuyPOList,
    IBuyPoViewSteps,
    IDeleteBuyPOIds,
    IGlobalInfo,
    IGlobalInfoError,
    IOrder,
    IOrdersAndFeeTotal,
} from '../interface/BuyPoInterface';
import { ICsvData } from '../../../../../utils/Interface/CommonInterface';

export const CREATE_BUY_PO_INITIAL_STATE = {
    buyPOList: [] as IBuyPOList[],
    filter: {
        buyPOId: '',
        purchaseFromDate: '',
        purchaseToDate: '',
        buyLocation: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        payee: '',
        truckingCompany: '',
        killGroup: '',
        isCattlePaid: '',
        isFreightPaid: '',
        isCattleReceived: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    } as IBuyPoFilter,
    buyPoReport: [] as ICsvData[],
    deleteBuyPOIds: [] as IDeleteBuyPOIds[],
    paymentLoading: true,
    loading: false,
    canCallService: true,
    buyPoGlobalInfo: {
        id: '',
        buyPoPurchaseDate: null,
        buyPoPickupDate: null,
        buyPoPickupFromDate: '',
        buyPoPickupToDate: '',
        buyPoLocation: '',
        buyPoLocationName: '',
        buyPoDestination: '',
        buyPoDestinationName: '',
        buyPoPayee: '',
        buyPoPayeeName: '',
        payee: '',
        buyPoTruckingCompany: '',
        buyPoTruckingCompanyName: '',
        buyPoBuyer: '',
        buyPoKillGroup: '',
        buyPoKillGroupName: '',
        buyPoBuyerName: '',
        files: [],
        buyPoFileArray: [],
        isAttachFileDisable: false,
        createdBy: '',
        createdDatetime: '',
        modifiedBy: '',
        modifiedDatetime: '',
        submittedBy: '',
        submittedDateTime: '',
        checkNumber: '',
        invoiceNumber: '',
        orderNotes: '',
        ccEmailState: false,
    } as IGlobalInfo,
    buyPoGlobalInfoError: {
        buyPoPurchaseDate: null,
        buyPoPickupDate: null,
        buyPoLocation: null,
        buyPoDestination: null,
        buyPoTruckingCompany: null,
    } as IGlobalInfoError,
    buyPOViewSteps: {
        step1: 'flex',
        step1Values: 'none',
        step1Icon: 'none',
        step2: 'none',
        step2Values: 'none',
        step2Next: 'none',
        step2NextValues: 'none',
        step2Icon: 'none',
        step3: 'none',
        step3Values: 'none',
        step3Icon: 'none',
    } as IBuyPoViewSteps,
    orders: [
        {
            orderId: '',
            orderName: '',
            orderNameError: '',
            notes: '',
            estimatedHeadCount: '',
            estimatedHeadCountError: '',
            headCount: '',
            headCountError: '',
            estimatedGrossWeightPerHd: '',
            estimatedGrossWeightPerHdError: '',
            grossWeight: '',
            grossWeightError: '',
            payWeight: '',
            payWeightError: '',
            totalDollars: '',
            owner: '',
            ownerName: '',
            ownerError: '',
            lot: '',
            baseWeight: '',
            shrink: '',
            price: '',
            slide: '',
        },
    ] as IOrder[],
    ordersAndFeeTotal: {
        headCountTotal: 0,
        estimatedHeadCountTotal: 0,
        grossWeightTotal: 0.0,
        estimatedGrossWeightPerHdTotal: 0.0,
        payWeightTotal: 0,
        totalDollarsTotal: 0.0,
        feeAmountTotal: 0.0,
        avgGrossWeight: 0.0,
        grandTotal: 0.0,
    } as IOrdersAndFeeTotal,
    paymentInfo: {
        isCattlePaid: false,
        isFreightPaid: false,
        isCattleReceived: false,
    },
    fees: [{ ...BuyPoFeesConstants.initialState.initialFee }] as IBuyPoFee[],
    lastModifiedDate: '',
    lastModifiedUser: '',
    selectedDestinationDetail: {},
    isSubmitted: false,
    ORDERS_INITIAL: {
        orderId: '',
        orderName: '',
        orderNameError: '',
        notes: '',
        estimatedHeadCount: '',
        estimatedHeadCountError: '',
        headCount: '',
        headCountError: '',
        estimatedGrossWeightPerHd: '',
        estimatedGrossWeightPerHdError: '',
        grossWeight: '',
        grossWeightError: '',
        payWeight: '',
        payWeightError: '',
        totalDollars: '',
        owner: '',
        ownerName: '',
        ownerError: '',
        lot: '',
        baseWeight: '',
        shrink: '',
        price: '',
        slide: '',
    } as IOrder,
};
