import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import Constants from '../Constants';
import dayjs from '../dayjs';

const {
    isCA9512On_TreatmentCurrentWeightRequired,
    isCA9808On_HomeAndCurrentPenLockdown,
} = feedlotManagerFeatures;

export const TreatmentsConstants = {
    mandatoryFields: [
        'lotId',
        'animalTag',
        'sex',
        ...(!isCA9808On_HomeAndCurrentPenLockdown ? ['homePenId'] : []),
        'currentPenId',
        'owner',
        'diagnosisId',
        'severityId',
        'date',
        'treatmentProtocolId',
        'movePenId',
        'techId',
        ...(isCA9512On_TreatmentCurrentWeightRequired ? ['currentWeight'] : []),
    ],
    apiFields: {
        date: 'date',
        lotId: 'lotId',
        animalId: 'animalId',
        diagnosisId: 'diagnosisId',
        treatmentId: 'treatmentId',
        riderId: 'riderId',
    },
    en: {
        button: {
            addTreatment: 'Add Treatment',
            exportToExcel: 'Export to Excel',
            okayButton: 'Okay',
            clearFilter: 'Clear Filter',
        },
        errorMessage: {
            mandatoryFields: 'Fill in Mandatory Fields',
        },
        informationMessage: {
            animalTreatment: 'This Animal has been treated within 2 days',
        },
        list: {
            limit: 20,
            offset: 0,
            table: {
                navigation: Constants.Routes.UPDATE_TREATMENT,
                routeParameter: 'cattleTreatmentId',
                headerAlign: 'text-center',

                headers: {
                    dateRange: {
                        label: 'Date Range',
                        apiResName: 'date',
                        type: 'date',
                    },
                    lot: {
                        label: 'Lot',
                        apiResName: 'lotName',
                    },
                    animalId: {
                        label: 'Animal ID',
                        apiResName: 'animalTag',
                    },
                    diagnosis: {
                        label: 'Diagnosis',
                        apiResName: 'diagnosisName',
                    },
                    treatment: {
                        label: 'Treatment',
                        apiResName: 'treatmentProtocolName',
                    },
                    rider: {
                        label: 'Rider',
                        apiResName: 'riderName',
                    },
                },
            },
        },
        placeholders: {
            searchDateRange: 'Search Date Range',
            searchLot: 'Search Lot',
            searchAnimalId: 'Search Animal ID',
            searchDiagnosis: 'Search Diagnosis',
            searchTreatment: 'Search Treatment',
            searchRider: 'Search Rider',
        },

        excelName: 'Treatment History',
        treatmentDetails: [
            {
                date: '',
                lotName: 'Treatment History',
                animalTag: '',
                homePen: '',
                currentPen: '',
                movePen: '',
                sexName: '',
                ownerName: '',
                diagnosisName: '',
                severity: '',
                lotAverageCurrentWeight: 'Date : ',
                currentWeight: dayjs().toDate(),
                temperature: '',
                treatmentProtocolName: '',
                techName: '',
                riderName: '',
                comments: '',
            },
            {
                date: '',
                lotName: '',
                animalTag: '',
                homePen: '',
                currentPen: '',
                movePen: '',
                sexName: '',
                ownerName: '',
                diagnosisName: '',
                severity: '',
                lotAverageCurrentWeight: '',
                currentWeight: '',
                temperature: '',
                treatmentProtocolName: '',
                techName: '',
                riderName: '',
                comments: '',
            },
            {
                date: 'Date',
                lotName: 'Lot',
                animalTag: 'Animal ID',
                homePen: 'Home Pen',
                currentPen: 'Current Pen',
                movePen: 'Move To Pen',
                sexName: 'Sex',
                ownerName: 'Owner',
                diagnosisName: 'Diagnosis',
                severity: 'Severity',
                lotAverageCurrentWeight: 'Lot Average Weight',
                currentWeight: 'Current Weight',
                temperature: 'Temperature',
                treatmentProtocolName: 'Treatment Protocol',
                techName: 'Tech',
                riderName: 'Rider',
                comments: 'Comments',
            },
        ],
        techNameHeader: 'Tech Name',
        createNewTech: 'Create New Tech',
        riderNameHeader: 'Rider Name',
        createNewRider: 'Create New Rider',
    },
    selectedTreatmentsDateRange: {
        startDate: dayjs().toDate(),
        endDate: dayjs().toDate(),
        key: 'selection',
    },
    treatmentsFilter: 'treatmentsFilter',
    es: {
        button: {
            addTreatment: 'Agregar tratamiento',
            exportToExcel: 'Exportar a Excel',
            okayButton: 'Bien',
            clearFilter: 'Filtro claro',
        },
        errorMessage: {
            mandatoryFields: 'Rellene los campos obligatorios',
        },
        informationMessage: {
            animalTreatment: 'Este animal ha sido tratado en 2 días',
        },
        list: {
            $top: 20,
            $skip: 0,
            table: {
                navigation: Constants.Routes.UPDATE_TREATMENT,
                routeParameter: 'cattleTreatmentId',
                headerAlign: 'text-center',

                headers: {
                    dateRange: {
                        label: 'Rango de fechas',
                        apiResName: 'date',
                        type: 'date',
                    },
                    lot: {
                        label: 'Lote',
                        apiResName: 'lotName',
                    },
                    animalId: {
                        label: 'Identificación de animales',
                        apiResName: 'animalTag',
                    },
                    diagnosis: {
                        label: 'Diagnóstico',
                        apiResName: 'diagnosisName',
                    },
                    treatment: {
                        label: 'Tratamiento',
                        apiResName: 'treatmentProtocolName',
                    },
                    rider: {
                        label: 'Jinete',
                        apiResName: 'riderName',
                    },
                },
            },
        },

        placeholders: {
            searchDateRange: 'Intervalo de fechas de búsqueda',
            searchLot: 'Buscar lote',
            searchAnimalId: 'Buscar ID de animal',
            searchDiagnosis: 'Buscar Diagnóstico',
            searchTreatment: 'Tratamiento de búsqueda',
            searchRider: 'Buscar jinete',
        },

        excelName: 'Historial de tratamiento',
        treatmentDetails: [
            {
                date: '',
                lotName: 'Historial de tratamiento',
                animalTag: '',
                homePen: '',
                currentPen: '',
                movePen: '',
                sexName: '',
                ownerName: '',
                diagnosisName: '',
                severity: '',
                lotAverageCurrentWeight: 'Fecha: ',
                currentWeight: dayjs().toDate(),
                temperature: '',
                treatmentProtocolName: '',
                techName: '',
                riderName: '',
                comments: '',
            },
            {
                date: '',
                lotName: '',
                animalTag: '',
                homePen: '',
                currentPen: '',
                movePen: '',
                sexName: '',
                ownerName: '',
                diagnosisName: '',
                severity: '',
                lotAverageCurrentWeight: '',
                currentWeight: '',
                temperature: '',
                treatmentProtocolName: '',
                techName: '',
                riderName: '',
                comments: '',
            },
            {
                date: 'Fecha',
                lotName: 'Lote',
                animalTag: 'Etiqueta de animales',
                homePen: 'InicioBolígrafo',
                currentPen: 'pluma actual',
                movePen: 'Mover a pluma',
                sexName: 'Nombre de sexo',
                ownerName: 'nombre del dueño',
                diagnosisName: 'Nombre diagnóstico',
                severity: 'Gravedad',
                lotAverageCurrentWeight: 'lote Peso actual promedio',
                currentWeight: 'Peso actual',
                temperature: 'temperatura',
                treatmentProtocolName: 'Nombre del protocolo de tratamiento',
                techName: 'nombre tecnico',
                riderName: 'Nombre del jinete',
                comments: 'Comentarios',
            },
        ],
        techNameHeader: 'Nombre técnico',
        createNewTech: 'Crear Nuevo Técnico',
        riderNameHeader: 'Nombre del jinete',
        createNewRider: 'Crear Nuevo Ciclista',
    },
};

export const EditTreatmentsConstants = {
    mixedSex: 'Mixed',
    treatedWithinTwoDays: {
        present: 0,
        past: -2,
    },
    apiFields: {
        lotId: 'lotId',
        animalId: 'animalId',
        diagnosisId: 'diagnosisId',
        sex: 'sex',
        homePenId: 'homePenId',
        movePenId: 'movePenId',
        severityId: 'severityId',
        currentWeight: 'currentWeight',
        temperature: 'temperature',
        treatmentProtocolId: 'treatmentProtocolId',
        comments: 'comments',
        cleanDate: 'cleanDate',
        techId: 'techId',
        riderId: 'riderId',
    },
    dropDownName: {
        animalId: {
            animalId: 'animalId',
            animalTag: 'animalTag',
        },
        lots: {
            lotId: 'lotId',
            name: 'name',
        },
        techs: {
            techId: 'techId',
            name: 'name',
        },
        riders: {
            riderId: 'riderId',
            name: 'name',
        },
        treatmentProtocols: {
            treatmentProtocolId: 'treatmentProtocolId',
            name: 'name',
        },
        diagnosis: {
            diagnosisId: 'diagnosisId',
            name: 'name',
        },
        sex: {
            sexId: 'sexId',
            name: 'name',
        },
        medicalItems: {
            medicalItemId: 'medicalItemId',
            name: 'name',
        },
        history: {
            name: 'name',
            cattleTreatmentId: 'cattleTreatmentId',
            createdDateTime: 'createdDateTime',
        },
    },
};
