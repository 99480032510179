export const vendorConstants = {
    createNewVendors: {
        heading: 'Create New Vendor',
        form: {
            name: {
                name: 'name',
                label: 'Vendor Name',
                maxLength: 50,
                placeholder: 'Enter Vendor Name',
            },
            contactName: {
                name: 'contactName',
                label: 'Contact Name ',
                maxLength: 50,
                placeholder: 'Enter Contact Name',
            },
            contactNumber: {
                name: 'contactNumber',
                label: 'Phone Number',
                maxLength: 50,
                placeholder: 'Enter Phone Number',
            },
            address: {
                name: 'address',
                label: 'Address',
                maxLength: 50,
                placeholder: 'Enter Address',
            },
            state: {
                name: 'state',
                label: 'State',
                maxLength: 50,
                placeholder: 'Enter State',
            },
            zipCode: {
                name: 'zipCode',
                label: 'Zip Code',
                maxLength: 50,
                placeholder: 'Enter Zipcode',
            },
            city: {
                name: 'city',
                label: 'City',
                maxLength: 50,
                placeholder: 'Enter City',
            },
            cancel: 'Cancel',
            saveVendors: 'Save Vendors',
        },
    },
    vendorDetails: {
        vendorPlaceholder: 'Search Vendor',
        lotPlaceholder: 'Select Lot',
        maxLength: 50,
        vendor: 'Vendor',
        lot: 'Lot',
        contactNumber: 'Phone Number',
        heading: 'Manage Vendors',
        name: 'vendor',
    },
    vendorColumnDetail: [
        { label: 'Vendor', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'Contact Name', key: 'contactName' },
        { label: 'Phone Number', key: 'contactNumber' },
        { label: 'Address', key: 'address' },
        { label: 'Zip Code', key: 'zipCode' },
        { label: 'State', key: 'state' },
        { label: 'City', key: 'city' },
    ],
    initialVendorDetailsError: {
        name: false,
        contactNumber: false,
        contactName: false,
        address: false,
        city: false,
        state: false,
        zipCode: false,
    },
    activateModalConstant: {
        heading: 'Activate Vendor',
        text: ' is currently Inactive. Activate and use this Vendor.',
    },
    createVendors: 'Create New Vendor',
    clearFilter: 'Clear Filter',
    editVendor: 'Edit Vendor - ',
    vendorFilter: 'vendorFilter',
};
