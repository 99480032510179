import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { COMMON_INITIAL_STATE } from '../Constants/commonInitialState';

const CommonReducer = (state = COMMON_INITIAL_STATE, action: AnyAction) => {
    switch (action.type) {
        case ActionTypes.FETCH_WINDOW_SIZE:
            return {
                ...state,
                windowSize: action.payload,
            };
        case ActionTypes.SET_ACTIVE:
            return {
                ...state,
                isNavActive: action.payload,
            };
        case ActionTypes.TOAST_MESSAGE:
            return {
                ...state,
                toastMessage: action.payload,
            };
        case ActionTypes.FETCH_FEE_TYPES:
            return {
                ...state,
                feeTypes: action.payload,
            };
        case ActionTypes.SET_SELECTED_FEEDLOT:
            return {
                ...state,
                selectedFeedlot: action.payload,
            };
        case ActionTypes.SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        default:
            return state;
    }
};

export default CommonReducer;
