import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFinanceManagerFeatures } from './interface';

export const financeManagerFeatures: IFinanceManagerFeatures = {
    isFA201AutoSaveFeedBills: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA201_AUTO_SAVE_FEEDBILLES,
    ),
    isFA467DefaultValueZeroRemoveAsterisks: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA467_DEFAULT_VALUE_ZERO_REMOVE_Asterisks,
    ),
    isFA464UseDayjsInForm: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA446_USE_DAYJS_IN_FORM,
    ),
    isFA463UseDayjsInCalculation: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA463_USE_DAYJS_IN_CALCULATION_FILE,
    ),
    isFA469ClosedLotsAreDisplayingTotal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA469_CLOSED_LOT_TOTAL_VALUE,
    ),
    isFA471PlusRowButtonAllowAddMultipleRow: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA471_PLUS_ROW_BUTTON_ALLOW_ADD_MULTI_ROW,
    ),
    isFA472AddSpaceBetweenHeaderInSettingPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA472_ADD_SPACE_IN__HEADING_IN_SETTING_PAGE,
    ),
    isFA499AddOwnerDropdownOnOwnerReportPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA499_ADD_OWNER_DROPDOWN_ON_OWNER_REPORT_PAGE,
    ),
    isFA500SpellMistakeAndDollarSignIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA500_SPELL_MISTAKE_IN_LOT_BALANCE_REPORT,
    ),
    isFA498ChangeDateFormat: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA498_CHANGE_DATE_FORMAT,
    ),
    isFA497LotFromAPiProdAlert: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA497_LOT_FORM_API_PROD_ALERT,
    ),
    isFA504On_QuarterlyReportLotsIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA504_QUARTERLY_REPORT_LOTS_ISSUE,
    ),
    isFA511AddQuickBookInQuarterlyReport: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA511_ADD_QUICKBOOK_NUMBER_IN_QUARTERLY_REPORT,
    ),
    isFA509showWarningForUnsavedFeedBills: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA509_SHOWING_WARNING_FOR_UNSAVED_FEEDBILLS,
    ),
    isFA518relotLableChange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA518_RELOT_LABEL_CHANGE,
    ),
    isFA523ValueUpdateOnContactTab: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA523_VALUE_UPDATE_ON_CONTACT_TAB,
    ),
    isFA519addToastMessageInRelotPage: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA519_TOAST_MESSAGE_IN_RELOT_API,
    ),
};
