import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../Store';

//docs: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const rootApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        timeout: 30000,
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: () => ({}),
    //IRFTODO create a file for each api and import here?
    tagTypes: ['FeedCall', 'PenRationSchedule'],
});
