import { Breadcrumb } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { firstLetterUpper } from '../helpers';
import Constants from './Constants';
import { useOwnerContext } from '../Context/OwnerContext/OwnerContext';

const BreadCrumbs = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { selectedOwner } = useOwnerContext();
    const pathnames = pathname.split('/').filter(x => x);
    const routePath = pathnames?.filter(element => isNaN(+element));
    const routePathValues = routePath?.map(
        element => Constants.headerConstants[element.toLowerCase()],
    );
    const [isSingleLotPage, setIsSingleLotPage] = useState<boolean>(false);

    const checkLotUrl = (url: string) => {
        const regex = /\/lot\/(.*)/;
        const match = url.match(regex);
        if (match && match[1]) {
            setIsSingleLotPage(true);
        } else {
            setIsSingleLotPage(false);
        }
    };

    const getFirstName = (index: number, name?: string) => {
        if (name === undefined) {
            return routePath[index - 1] === 'editFeedBill'
                ? firstLetterUpper('Feed Bill')
                : firstLetterUpper(routePath[index - 1]);
        } else {
            return firstLetterUpper(name);
        }
    };

    const handleNavigate = (name: string) => {
        if (name === 'Feed Bills') {
            navigate('feedbills');
        } else {
            navigate(name.replaceAll(' ', ''));
        }
    };

    useEffect(() => {
        checkLotUrl(pathname);
    }, [pathname]);

    return (
        <>
            {!(
                (pathname === '/lots' && selectedOwner.name !== 'All') ||
                isSingleLotPage
            ) && (
                <Breadcrumb className="breadcrumb">
                    {routePathValues.length > 0 ? (
                        <Breadcrumb.Item onClick={() => navigate('/')}>
                            Home
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item> Home </Breadcrumb.Item>
                    )}
                    {routePathValues?.map((name, index) => {
                        const isLast = index === +routePath.length - 1;
                        return isLast ? (
                            <Breadcrumb.Item active key={index}>
                                {getFirstName(index, name)}
                            </Breadcrumb.Item>
                        ) : (
                            <Breadcrumb.Item
                                key={index}
                                onClick={() => handleNavigate(name)}
                            >
                                {firstLetterUpper(name)}
                            </Breadcrumb.Item>
                        );
                    })}
                </Breadcrumb>
            )}
        </>
    );
};

export default BreadCrumbs;
