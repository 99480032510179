import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateTruckingCompany,
    IDeleteTruckingCompany,
    IFetchTruckingCompanyDetails,
    IFilter,
    ITruckingCompanyDetail,
    IUpdateTruckingCompanyDetail,
} from '../../utils/Interface/TruckingCompanyInterface';
import { getToastContent } from '../../utils/ToastContent';
import { setTruckingCompanyDetailsLoader } from '../Reducer/truckingCompanySlice';
import { AppDispatch } from '../Store';
import { toastMessage } from './commonActions';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and isActive eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchTruckingCompanyDetails: IFetchTruckingCompanyDetails =
    (setTruckingCompanyDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setTruckingCompanyDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}/odata?${filterUrl}`,
        });
        setTruckingCompanyDetail(response ?? []);
        dispatch(setTruckingCompanyDetailsLoader(false));
    };

export const updateTruckingCompanyDetail: IUpdateTruckingCompanyDetail =
    (
        truckingCompanyDetail,
        fetchTruckingCompanyData?,
        closeModalWithChanges?,
        setLoading?,
        handleActivateTruckingCompany?,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}/${truckingCompanyDetail.companyId}`,
            payload: truckingCompanyDetail,
            isResRequired: true,
        });

        setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.TRUCKING_COMPANY_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTruckingCompany?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            closeModalWithChanges?.(true, truckingCompanyDetail.companyId);
        }
        fetchTruckingCompanyData?.();
    };

export const createTruckingCompany: ICreateTruckingCompany =
    (
        truckingCompany,
        closeModalWithChanges,
        setLoading,
        handleActivateTruckingCompany,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}`,
            payload: truckingCompany,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.TRUCKING_COMPANY_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTruckingCompany();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveTruckingCompanyInfo =
    (truckingCompany?: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}/odata?filter=name eq '${truckingCompany}'`,
            isResRequired: true,
        });
        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteTruckingCompany: IDeleteTruckingCompany =
    (truckingCompany, fetchTruckingCompanyData?) =>
    async (dispatch: AppDispatch) => {
        dispatch(setTruckingCompanyDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_TRUCKING_COMPANY}/${truckingCompany.companyId}`,
            payload: truckingCompany,
            isResRequired: true,
        });
        dispatch(setTruckingCompanyDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchTruckingCompanyData?.();
    };
