import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonActionTypes } from '../../../Redux/Actions';
import { YARD_SHEET_INITIAL_STATE } from './yardSheetConstants';
import { IYardSheetExcelData } from './yardSheetInterfaces';

const yardSheetSlice = createSlice({
    name: 'yardSheet',
    initialState: YARD_SHEET_INITIAL_STATE,
    reducers: {
        setYardSheetExcelData: (
            state,
            action: PayloadAction<IYardSheetExcelData[]>,
        ) => {
            state.yardSheetExcelData = action.payload;
        },
        setYardSheetForExcelLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isYardSheetExcelDataLoading = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(CommonActionTypes.SET_SELECTED_FEEDLOT, state => {
            state.yardSheetExcelData =
                YARD_SHEET_INITIAL_STATE.yardSheetExcelData;
        });
    },
});

export const yardSheetActions = yardSheetSlice.actions;
export const yardSheetReducer = yardSheetSlice.reducer;
