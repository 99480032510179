import { toastMessage } from '../../../../../../Redux/Actions/commonActions';
import { AppDispatch } from '../../../../../../Redux/Store';
import { apiCall_v2 } from '../../../../../../Services/AxiosService';
import { t } from 'i18next';
import { sortAlphaNumeric } from '../../../../../../helpers';
import Constants from '../../../../../../utils/Constants';
import { getToastContent } from '../../../../../../utils/ToastContent';
import {
    UserStatusEnum,
    IUser,
    IUserManagementFilter,
    IRole,
} from '../interfaces/UserManagementInterface';
import { userManagementActions } from '../reducer/userManagementSlice';

export const getFeedlots = () => async (dispatch: AppDispatch) => {
    const response = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.ACCOUNTS}${Constants.apiUrls.ODATA}?$orderBy=Name`,
        isResRequired: true,
    });

    if (response.status === Constants.responseCode.SUCCESS) {
        if (response.data?.length) {
            dispatch(userManagementActions.setFeedlots(response.data));
        }
    } else {
        dispatch(toastMessage(getToastContent(response.status)));
    }
};

const getUserQueryString = (filter: IUserManagementFilter) => {
    const filterConditions = [
        ...(filter.name
            ? [
                  `contains(concat(concat(FirstName, ' '), LastName), '${filter.name}')`,
              ]
            : []),
        ...(filter.email ? [`contains(Username, '${filter.email}')`] : []),
        ...(+filter.feedlotId
            ? [
                  `(Accounts/any(account:account/AccountId eq ${filter.feedlotId}) or Accounts/any() eq false)`,
              ]
            : []),
        ...(filter.app &&
        filter.app !==
            t('userManagement:userManagementLabels.staticOptions.selectApp')
            ? [
                  `Scopes/any(scope:scope/name eq 'APP:${filter.app.replace(/\s/g, '')}')`,
              ]
            : []),
        ...(filter.status !== null ? [`Enabled eq ${filter.status}`] : []),
    ];

    const filterClause = filterConditions.filter(Boolean).join(' and ');
    const filterQuery = filterClause ? `&$filter=${filterClause}` : '';
    const roleQuery =
        filter.role &&
        filter.role !==
            t('userManagement:userManagementLabels.staticOptions.selectRole')
            ? `&role=${filter.role.replace(/\s/g, '')}`
            : '';

    const queryString =
        `?include=Roles,Scopes,Accounts.Roles&$top=${filter.limit}&$skip=${filter.offset}&$orderBy=FirstName, LastName` +
        filterQuery +
        roleQuery;

    return queryString;
};

const filterApps = apps => {
    return apps.filter(app => app.name.startsWith('APP:'));
};

const formatAppName = (appName: string) => {
    return appName.replace('APP:', '').replace(/(?<!^)([A-Z])/g, ' $1');
};

const newFormatAppName = (appName: string) => {
    const result =
        appName.charAt(0).toUpperCase() +
        appName.slice(1).replace(/(?<!^)([A-Z])/g, ' $1');
    return result;
};

export const getUsers =
    (filter: IUserManagementFilter) => async (dispatch: AppDispatch) => {
        dispatch(userManagementActions.setUserManagementLoading(true));

        const queryString = getUserQueryString(filter);

        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.USERS}${Constants.apiUrls.ODATA}${queryString}`,
            isResRequired: true,
        });

        if (response.status === Constants.responseCode.SUCCESS) {
            const formattedUsers: IUser[] = response.data?.map(user => {
                const userRoles: IRole[] = user.roles.map(role => role.name);
                const appRoles: IRole[] = user.accounts?.map(account =>
                    account.roles.map(
                        role => `${account.account.name} - ${role.name}`,
                    ),
                );
                const roles = [...userRoles, ...appRoles];
                const apps = filterApps(user.scopes).map(app => {
                    return formatAppName(app.name);
                });
                return {
                    name: user.fullName,
                    email: user.username,
                    apps,
                    roles,
                    status: user.enabled
                        ? UserStatusEnum.Active
                        : UserStatusEnum.Inactive,
                    resetPassword: false,
                    editUser: false,
                };
            });

            if (filter.offset > 0) {
                dispatch(userManagementActions.appendUsers(formattedUsers));
            } else {
                dispatch(userManagementActions.setUsers(formattedUsers));
            }
        } else {
            dispatch(userManagementActions.setUsers([]));
            dispatch(toastMessage(getToastContent(response.status)));
        }

        dispatch(userManagementActions.setUserManagementLoading(false));
    };

export const getApps = () => async (dispatch: AppDispatch) => {
    const response = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.TENANTS}`,
        isResRequired: true,
    });

    if (response.status === Constants.responseCode.SUCCESS) {
        if (response.data) {
            let apps = sortAlphaNumeric(
                response.data.profile?.subscriptions,
                'productFamily',
            );
            apps = apps.map(app => newFormatAppName(app.productFamily));
            dispatch(userManagementActions.setApps(apps));
        }
    } else {
        dispatch(toastMessage(getToastContent(response.status)));
    }
};
