import { Button, Col } from 'react-bootstrap';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { truckConstants } from '../../utils/constants/TruckConstant';
import Constants from '../../utils/Constants';
import { useEffect, useState, useMemo } from 'react';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteTruck,
    fetchTruckDetails,
    updateTruckDetail,
} from '../../Redux/Actions/truckActions';
import { IFilter, ITruckDetail } from '../../utils/Interface/TruckInterface';
import { setTruckFilter } from '../../Redux/Reducer/truckReducer';
import TruckModal from './TruckModal';
import { TRUCK_INITIAL_STATE } from '../../Redux/Constants/truckInitialState';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';

const TruckDetails = () => {
    const { filter, loader } = useAppSelector(state => state.truck);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [truckDetails, setTruckDetails] = useState<ITruckDetail[]>([]);
    const [selectedTruck, setSelectedTruck] = useState<ITruckDetail>(
        TRUCK_INITIAL_STATE.createTruckInitial,
    );
    const storage = useMemo(() => new StorageService(), []);
    const {
        isOpen: isTruckModalOpen,
        open: openTruckModal,
        close: closeTruckModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const handleEditTruckDetail = (truck: ITruckDetail): void => {
        setSelectedTruck(truck);
        openTruckModal();
    };

    const fetchTruckData = (truckFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(
                fetchTruckDetails(feedlotId, setTruckDetails, truckFilter),
            );
        }
    };

    const setTruckFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            truckConstants.truckFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string): void => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchTruckData(updatedFilter);
        dispatch(setTruckFilter(updatedFilter));
        setTruckFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited && feedlotId) {
            fetchTruckData(filter);
        }
        closeTruckModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = TRUCK_INITIAL_STATE.filter;
        fetchTruckData(updatedFilter);
        setSearchQuery('');
        dispatch(setTruckFilter(updatedFilter));
        setTruckFilterInStorage(updatedFilter);
    };

    const handleCreateTruck = (): void => {
        if (feedlotId) {
            setSelectedTruck({
                ...TRUCK_INITIAL_STATE.createTruckInitial,
                feedLotId: feedlotId,
            });
        }
        openTruckModal();
    };

    const handleDeleteModalHandler = (truck: ITruckDetail) => {
        setSelectedTruck(truck);
        openDeleteModal();
    };

    const handleDeleteTruck = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedTruck.isFeedTaskAssociated || selectedTruck.active) {
                const isActiveAndAssociated =
                    selectedTruck.isFeedTaskAssociated && selectedTruck.active;
                const action = isActiveAndAssociated
                    ? updateTruckDetail
                    : deleteTruck;
                const updatedTruckDetail = isActiveAndAssociated
                    ? { ...selectedTruck, active: false }
                    : selectedTruck;
                dispatch(action(feedlotId, updatedTruckDetail, fetchTruckData));
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const truckFilter = storage.getValue(truckConstants.truckFilter);
            if (Object.keys(truckFilter).length > 0) {
                dispatch(setTruckFilter(truckFilter));
            }
            if (!truckFilter.name) {
                if (truckFilter.show) {
                    fetchTruckData(truckFilter);
                } else {
                    dispatch(
                        fetchTruckDetails(feedlotId, setTruckDetails, filter),
                    );
                }
            } else {
                setSearchQuery(truckFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{truckConstants.truckDetails.heading}</h5>
            <div className="d-flex align-items-end mb-4">
                <Col xs={3} className="d-flex flex-column">
                    <label>{truckConstants.truckDetails.truck}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={truckConstants.truckDetails.placeholder}
                        maxLength={truckConstants.truckDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {truckConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateTruck}>
                        {truckConstants.createTrucks}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ITruckDetail>
                columnDetail={truckConstants.truckColumnDetail}
                itemDetail={truckDetails}
                handleEditDetail={handleEditTruckDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isTruckModalOpen && (
                <TruckModal
                    showModal={isTruckModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editTruckDetails={selectedTruck}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedTruck}
                    handleDeleteItem={handleDeleteTruck}
                    showModal={isDeleteModalOpen}
                    itemName={truckConstants.truckDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default TruckDetails;
