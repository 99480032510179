import {
    IProcessingProtocolFilter,
    IWorksheetProcessing,
} from './../../utils/Interface/HealthSection/MedicalItemInterface';
import { AnyAction } from '@reduxjs/toolkit';
import { IMedicalItem } from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { IProcessingProtocol } from '../../utils/Interface/ProcessingInterface';
import { ActionTypes } from '../Actions';
import { PROCESSING_PROTOCOL_INITIAL_STATE } from '../Constants/processingProtocolInitialState';

const ProcessingProtocolReducer = (
    state = PROCESSING_PROTOCOL_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_PROCESSING_PROTOCOL_MEDICAL_ITEMS:
            return {
                ...state,
                processingProtocolMedicalItems:
                    action.payload as IMedicalItem[],
            };
        case ActionTypes.SET_PROCESSING_PROTOCOLS:
            return {
                ...state,
                processingProtocols: action.payload as IProcessingProtocol[],
            };
        case ActionTypes.APPEND_PROCESSING_PROTOCOLS:
            return {
                ...state,
                processingProtocols: [
                    ...state.processingProtocols,
                    ...action.payload,
                ] as IProcessingProtocol[],
            };
        case ActionTypes.SET_WORKSHEET_PROCESSING:
            return {
                ...state,
                worksheetProcessing: action.payload as IWorksheetProcessing,
            };
        case ActionTypes.SET_GENERATED_INVOICE_CUTOFF_DATE:
            return {
                ...state,
                generatedInvoiceCutoffDate: action.payload,
            };
        case ActionTypes.SET_PROCESSING_PROTOCOLS_FILTER:
            return {
                ...state,
                processingFilter: {
                    ...state.processingFilter,
                    ...action.payload,
                } as IProcessingProtocolFilter,
            };
        case ActionTypes.SET_PROCESSING_PROTOCOL_INSUFFICIENT_INVENTORY:
            if (!state.insufficientInventory.includes(action.payload)) {
                return {
                    ...state,
                    insufficientInventory: [
                        ...state.insufficientInventory,
                        action.payload,
                    ],
                };
            }
            return state;
        case ActionTypes.REMOVE_PROCESSING_PROTOCOL_INSUFFICIENT_INVENTORY: {
            const indexToRemove = action.payload;
            const updatedInsufficientInventory = state.insufficientInventory
                .map(index => (index > indexToRemove ? index - 1 : index))
                .filter(index => index !== indexToRemove);
            return {
                ...state,
                insufficientInventory: updatedInsufficientInventory,
            };
        }
        case ActionTypes.CLEAR_PROCESSING_PROTOCOL_INSUFFICIENT_INVENTORY:
            return {
                ...state,
                insufficientInventory: [],
            };
        case ActionTypes.SET_PROCESSING_PROTOCOL_SUBMIT_PENDING:
            return {
                ...state,
                processingProtocolSubmitPending: action.payload,
            };
        default:
            return state;
    }
};

export default ProcessingProtocolReducer;
