import { currentDate_deprecated } from '../../helpers';
import { IRationInitialState } from '../../utils/Interface/RationInterface';
import { rationLabelConstants } from '../../utils/LabelConstant';

export const CREATE_RATION_INITIAL_STATE: IRationInitialState = {
    loading: false,
    isRationDetailChanged: false,
    rationDetail: {
        rationId: 0,
        name: '',
        categoryId: 1,
        markUp: '',
        lastEffectiveDate: '',
        lastEffectiveTime: '',
        prevEffectiveDate: '',
        effectiveDate: currentDate_deprecated('YYYY-MM-DD'),
        loadSize: '',
        comments: '',
        makeCurrentRation: true,
        nem: '',
        neg: '',
        customPrice: '',
        corporatePrice: '',
        rationVersion: 1,
        rationVersionId: 0,
    },
    rationErrorDetail: {
        name: false,
        categoryId: false,
        effectiveDate: false,
        loadSize: false,
        nem: false,
        neg: false,
    },
    ingredients: [
        { name: rationLabelConstants.Ingredients.SELECT_INGREDIENTS, id: 0 },
    ],
    rationIngredients: [
        {
            id: '',
            commodityId: '',
            amountPercentage: '',
            lbsPerLoad: '',
            dryMatterPercentage: '',
            price: '',
            isDisable: false,
        },
    ],
    rationIngredientsError: [
        {
            commodityId: false,
            amountPercentage: false,
            dryMatterPercentage: false,
            lbsPerLoad: false,
            price: false,
        },
    ],
    ingredientTotalAmount: 0,
    ingredientTotalPercentage: 0,
    dryMatterTotal: 0,
    allRations: [],
    isRationMarkupSubmitPending: false,
};
