import { Col, Spinner } from 'react-bootstrap';
import { CommonConstants } from '../../../utils/constants/CommonConstants';
import { penConstants } from '../../../utils/constants/PenDetailsConstant';
import {
    BaseDetail,
    IColumnDetail,
    ISettingsItemDetail,
} from '../../../utils/Interface/PenInterface';
import EditIcon from '../../../Assets/EditIcon';
import DeleteIcon from '../../../Assets/DeleteIcon';
import { yardageConstants } from '../../../utils/constants/YardageConstants';
import { formatDate } from '../../../helpers';

const SettingsItemDetail = <T extends BaseDetail>({
    columnDetail,
    itemDetail,
    handleEditDetail,
    handleDeleteModalHandler,
    loader,
}: ISettingsItemDetail<T>) => {
    const getColumnContent = (item: T, column: IColumnDetail) => {
        switch (column.key) {
            case penConstants.activeKey:
                return item[column.key]
                    ? penConstants.active
                    : penConstants.inActive;
            case yardageConstants.yardageRate:
                return `$${item[column.key].toFixed(2)}`;
            case yardageConstants.effectiveDate:
                return formatDate(item[column.key]);
            default:
                return item[column.key];
        }
    };

    return (
        <>
            <Col className="d-flex align-items-center p-2 mt-1">
                {columnDetail.map(column => (
                    <Col
                        xs={column.key == 'active' ? 1 : 1}
                        className="mx-3"
                        key={column.key}
                    >
                        <h6>{column.label}</h6>
                    </Col>
                ))}
            </Col>
            <div
                className={`${columnDetail[0].label == 'Yardage' ? 'yardage-item-detail-container-height' : 'settings-item-detail-container-height'}  settings-item-detail-container m-2`}
            >
                {loader && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner
                            animation="border"
                            data-size="lg"
                            className="text-center"
                        />
                    </div>
                )}
                {itemDetail.length === 0 && !loader && (
                    <Col>{CommonConstants.en.noDataFound}</Col>
                )}
                {itemDetail.map(item => (
                    <Col
                        className="d-flex align-items-center p-2 item-row settings-item-detail"
                        key={item.name}
                    >
                        {columnDetail.map(column => (
                            <Col
                                className="mx-3"
                                xs={column.key == 'active' ? 1 : 1}
                                key={column.key}
                            >
                                {getColumnContent(item, column)}
                            </Col>
                        ))}
                        <Col className="d-flex justify-content-end">
                            {handleEditDetail && (
                                <EditIcon
                                    className="cursor-pointer custom-icon mx-2"
                                    onClick={() => handleEditDetail(item)}
                                />
                            )}
                            {handleDeleteModalHandler && (
                                <DeleteIcon
                                    className="cursor-pointer custom-icon"
                                    onClick={() =>
                                        handleDeleteModalHandler(item)
                                    }
                                ></DeleteIcon>
                            )}
                        </Col>
                    </Col>
                ))}
            </div>
        </>
    );
};

export default SettingsItemDetail;
