import {
    ICsvData,
    IGenericFilter,
} from '../../../../utils/Interface/CommonInterface';

export interface IScaleTicketModal {
    show: boolean;
    onHide: () => void;
}
export interface IScaleTicketsFilter extends IGenericFilter {
    include: string;
    limit: number;
    offset: number;
    filter: string;
    sort: string;
}

export interface IPutPostEditScaleTicketPayload {
    tDate: string;
    dmPercentage?: number | string; //make non-optional when CA9800 is cleaned up
    contractId: number | null;
    contractStatus?: ScaleTicketContractStatus | null; //make non-optional when CA9800 is cleaned up
    commodityId?: number; //make non-optional when CA9800 is cleaned up
    vendorId?: number; //make non-optional when CA9800 is cleaned up
    price: number | string;
    grossWeight: number | string;
    tare: number | string;
    truckingCompanyId: number;
    freightRate?: number; //make non-optional when CA9800 is cleaned up
    driverName: string;
    notes: string;
    scaleType?: number;
    scaleTicketNumber: string;
    billOfLading?: number | string; //make non-optional when CA9800 is cleaned up
}

export type IFilterFieldBasicDetails = string;

export type IFilterFieldBasicDetailsError =
    | 'tDate'
    | 'commodity'
    | 'contractId'
    | 'grossWeight'
    | 'tare'
    | 'truckingCompanyId'
    | 'scaleType'
    | 'vendor'
    | 'scaleTicketNumber';

export interface IScaleTicketsResponse {
    scaleTicket: number;
    scaleTicketNumber: string;
    ticketDate: Date | null;
    commodity: string;
    vendor: string;
    contractName: string;
    netWeight: number;
    billOfLading: string | number;
    canDelete: boolean;
}

export type IScaleTicketsResponseHandlerFn = (
    response: IScaleTicketsResponse[],
) => void;

export interface IContract {
    name: string;
    id: number | string;
    commodityId?: number;
    vendorId?: number;
    contractId?: number;
    price: number | string;
    rate?: number;
    dmPercentage?: number;
}

export interface IUnit {
    name: string;
    id: number;
}

export interface IVendor {
    name: string;
    id: number | string;
}

export interface ICommodity {
    name: string;
    id: number | string;
}

export interface ITruckingCompany {
    name: string;
    id: number | string;
    companyId?: number;
}

export interface ICommodityDetail {
    name: string;
    id: number;
}

export enum FreightType {
    FOB = 'FOB',
    Delivered = 'Delivered',
}

export interface IEditScaleTicketDetails {
    tDate: string;
    commodity: number;
    contractId: number;
    price: number | string;
    grossWeight: number | string;
    tare: number | string;
    truckingCompanyId: number;
    freightRate?: number | string; //make non-optional when CA9800 is cleaned up
    dmPercentage?: number | string;
    driverName: string;
    notes: string;
    scaleType?: number;
    vendor: number;
    scaleTicketNumber: string;
    netWeight: number | string;
    netWeightInTons: number | string;
    billOfLading: number | string;
}

export interface IUpdateEditScaleTicketDetailsAction {
    tDate?: string;
    commodity?: number;
    contractId?: number;
    price?: number | string;
    grossWeight?: number | string;
    tare?: number | string;
    truckingCompanyId?: number;
    driverName?: string;
    notes?: string;
    scaleType?: number;
    vendor?: number;
    scaleTicketNumber?: string;
    netWeight?: number | string;
    netWeightInTons?: number | string;
    billOfLading?: number | string;
    freightRate?: number | string;
}

export interface IEditScaleTicketDetailsError {
    tDate: boolean;
    commodity: boolean;
    contractId: boolean;
    grossWeight: boolean;
    tare: boolean;
    truckingCompanyId: boolean;
    freightRate: boolean;
    scaleType: boolean;
    vendor: boolean;
    dmPercentage: boolean;
    scaleTicketNumber: boolean;
    billOfLading: boolean;
}

export interface IVendorModalDetails {
    name: string;
    contactName: string;
    contactNumber: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface IVendorModalDetailsError {
    name: boolean;
    contactName: boolean;
    contactNumber: boolean;
    address: boolean;
    city: boolean;
    state: boolean;
    zipCode: boolean;
}

export interface IContractFormDetails {
    contractName: string;
    vendorId: number;
    payee: string;
    contractCreationDate: string;
    commodityId: number;
    price: string | number;
    totalAmount: string | number;
    remainingAmount?: number;
    scaleTicketAmount?: number;
    dmPercentage: string | number;
    freightType?: FreightType;
    rate: string | number;
    notPriced?: boolean;
    comment: string | number;
    contractType: string;
}

export interface IContractPayload extends IContractFormDetails {
    freight: string;
}

export interface IContractFormDetailsError {
    contractName: boolean;
    vendorId: boolean;
    payee: boolean;
    contractCreationDate: boolean;
    commodityId: boolean;
    price: boolean;
    totalAmount: boolean;
    dmPercentage: boolean;
    freightType: boolean;
    rate: boolean;
    notPriced: boolean;
}

export interface ITruckingCompanyModaLDetails {
    name: string;
    email: string;
    altEmail: string;
    contactNumber: string;
    altContactNumber: string;
    contactName: string;
    altContactName: string;
}

export interface ITruckingCompanyModaLDetailsError {
    name: boolean;
    email: boolean;
    contactNumber: boolean;
    contactName: boolean;
}
export interface IEditScaleTicketBasicDetails {
    scaleTicketDetailsHandler: (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null,
        type?: string,
    ) => void;
    scaleTicketModalsHandler: (fieldName: string, fieldValue: string) => void;
}

export interface IEditScaleTicketAdvanceDetails {
    scaleTicketDetailsHandler: (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null,
        type?: string,
    ) => void;
    scaleTicketModalsHandler: (fieldName: string, fieldValue: string) => void;
}

export interface IScaleTicketInitialState {
    selectedScaleTicketTab: number;
    loading: boolean;
    canCallApi: boolean;
    scaleTicketsFilter: IScaleTicketsFilter;
    scaleTickets: IScaleTicketsResponse[];
    contractModalDetails: IContractFormDetails;
    contractModalDetailsError: IContractFormDetailsError;
    vendorModalDetails: IVendorModalDetails;
    vendorModalDetailsError: IVendorModalDetailsError;
    truckingCompanyModalDetails: ITruckingCompanyModaLDetails;
    truckingCompanyModalDetailsError: ITruckingCompanyModaLDetailsError;
    editScaleTicketDetails: IEditScaleTicketDetails;
    editScaleTicketDetailsError: IEditScaleTicketDetailsError;
    vendors: IVendor[];
    commodities: ICommodity[];
    contracts: IContract[];
    completeContracts: IContract[];
    truckingCompanies: ITruckingCompany[];
    freights: FreightType[];
    scaleTicketsExcelData: ICsvData[];
    submitPending: boolean;
}

export type IOnSubmit = () => void;
export type IOnCancel = () => void;
export interface IEditScaleTicketFooter {
    onSubmit: IOnSubmit;
    onCancel: IOnCancel;
}

export interface IGenericScaleTicketDispatch {
    type: string;
    payload:
        | IEditScaleTicketDetails
        | IPutPostEditScaleTicketPayload[]
        | IVendorModalDetails
        | IContractFormDetails
        | ITruckingCompanyModaLDetails
        | ITruckingCompany[]
        | IVendor[]
        | IUnit[]
        | IContract[]
        | boolean
        | number;
}

export interface IScaleTicketsReportModal {
    showModal: boolean;
    closeModal: () => void;
    feedlotId: number | undefined;
    scaleType: number;
}

export enum ScaleTicketContractStatus {
    NoContract = 'NoContract',
    Unassigned = 'Unassigned',
}
