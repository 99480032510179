import { AnyAction } from '@reduxjs/toolkit';
import {
    IFee,
    IFeeError,
    IReceiveCattleDetail,
    IReceiveCattleDetailError,
    IReceiveCattleLot,
    IReceiveCattlePen,
    IReceiveCattlePenError,
} from '../../utils/Interface/ReceiveCattleInterface';
import { ActionTypes } from '../Actions';
import { CREATE_RECEIVE_CATTLE_INITIAL_STATE } from '../Constants/createReceiveCattleInitialState';

const ReceiveCattleReducer = (
    state = CREATE_RECEIVE_CATTLE_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_RECEIVE_CATTLE_DETAIL:
            return {
                ...state,
                receiveCattleDetail: action.payload as IReceiveCattleDetail,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_PENS:
            return {
                ...state,
                receiveCattleAssignPens: action.payload as IReceiveCattlePen[],
            };

        case ActionTypes.SET_RECEIVE_CATTLE_DETAIL_ERROR:
            return {
                ...state,
                receiveCattleDetailError:
                    action.payload as IReceiveCattleDetailError,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_PENS_ERROR:
            return {
                ...state,
                receiveCattleAssignPensError:
                    action.payload as IReceiveCattlePenError[],
            };
        case ActionTypes.SET_RECEIVE_CATTLE_FEES:
            return {
                ...state,
                receiveCattleFees: action.payload as IFee[],
            };
        case ActionTypes.SET_RECEIVE_CATTLE_FEES_ERROR:
            return {
                ...state,
                receiveCattleFeesError: action.payload as IFeeError[],
            };
        case ActionTypes.SET_FEES_TOTAL:
            return {
                ...state,
                feeTotalAmount: action.payload as number,
            };
        case ActionTypes.SET_GENERATED_INVOICE_CUTOFF_DATE:
            return {
                ...state,
                generatedInvoiceCutoffDate: action.payload as string,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_LOTS:
            return {
                ...state,
                lots: action.payload as IReceiveCattleLot[],
            };
        case ActionTypes.SET_RECEIVE_CATTLE_LOADING:
            return {
                ...state,
                loading: action.payload as boolean,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_OWNERS:
            return {
                ...state,
                owners: action.payload,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_ORIGINS:
            return {
                ...state,
                origins: action.payload,
            };
        case ActionTypes.SET_RECEIVE_CATTLE_REPORTS:
            return {
                ...state,
                receiveCattleReports: action.payload,
            };
        default:
            return state;
    }
};

export default ReceiveCattleReducer;
