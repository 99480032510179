import { createSlice } from '@reduxjs/toolkit';
import { CREATE_MOVE_CATTLE_INITIAL_STATE } from '../Constants/createMoveCattleInitialState';

const moveCattleSlice = createSlice({
    name: 'moveCattle',
    initialState: CREATE_MOVE_CATTLE_INITIAL_STATE,
    reducers: {
        setMoveCattleLots: (state, action) => {
            state.lots = action.payload;
        },
        setMoveCattleBasicDetail: (state, action) => {
            state.moveCattleBasicDetail = action.payload;
        },
        setMoveCattleFromPens: (state, action) => {
            state.fromPens = action.payload;
        },
        setMoveCattlePens: (state, action) => {
            state.pens = action.payload;
        },
        setMoveCattleToPens: (state, action) => {
            state.toPens = action.payload;
        },
        setMoveCattleBasicDetailError: (state, action) => {
            state.moveCattleBasicDetailError = action.payload;
        },
        setMoveCattleIsHistoricRecord: (state, action) => {
            state.isHistoricRecord = action.payload;
        },
        setMoveCattleBasicDetailHeadCount: (state, action) => {
            state.moveCattleBasicDetail.lotHdCount = action.payload;
        },
        setMovementHistory: (state, action) => {
            state.movementHistory = action.payload;
        },
        setMovementSubmitPending: (state, action) => {
            state.isMovementSubmitPending = action.payload;
        },
    },
});

export const moveCattleActions = moveCattleSlice.actions;
export const moveCattleReducer = moveCattleSlice.reducer;
