import { combineReducers } from '@reduxjs/toolkit';
import { yardSheetReducer } from '../../modules/cattleManagement/yardSheet/yardSheetSlice';
import manageCommoditiesReducer from '../../modules/commodities/manageCommodities/reducers/manageCommoditiesReducer';
import CommodityInventoryReportReducer from '../../modules/commodities/reports/reducers/commodityInventoryReportReducer';
import ScaleTicketReducer from '../../modules/commodities/scaleTickets/reducer/scaleTicketReducer';
import FeedReportReducer from '../../modules/feed/feedReports/reducers/feedReportsReducer';
import FeedCallRoutesReducer from '../../modules/feed/feedCallRoute/reducers/feedCallRoutesReducer';
import FinanceLotId from './FinanceLotId';
import { tenantSettingsReducer } from './TenantSettingsReducer';
import billingReducer from './billingReducer';
import BuyPoReducer from '../../apps/shippingTracker/modules/BuyPo/reducer/buyPoReducer';
import cattlePoCalendarReducer from '../../apps/shippingTracker/modules/calendar/reducers/cattlePoCalendarReducer';
import CommonReducer from './common';
import databricksReducer from './databricksReducer';
import DiagnosisReducer from './diagnosisReducer';
import FeedCallReducer from './feedCallReducer';
import FeedRouteCreationReducer from './feedRouteCreationReducer';
import FeedStatusReducer from './feedStatusReducer';
import HospitalReducer from './hospitalReducer';
import HotListReducer from './hotListReducer';
import { lotReducer } from './lotSlice';
import ManuaLFeedEntryReducer from './manualFeedEntryReducer';
import MedicalInventoryReducer from './medicalInventoryReducer';
import MedicalItemsReducer from './medicalItemsReducer';
import MortalityReportsReducer from './mortalityReportReducer';
import { moveCattleReducer } from './moveCattleSlice';
import PenRationScheduleReducer from './penRationScheduleReducer';
import PenRationTemplatesReducer from './penRationTemplatesReducer';
import { processingLotReducer } from './processingLotSlice';
import ProcessingProtocolReducer from './processingProtocolReducer';
import FeedRationOrderReducer from './rationFeedOrderReducer';
import RationReducer from './rationReducer';
import ReceiveCattleReducer from './receiveCattleReducer';
import ShipCattleRealizerReducer from './shipCattleRealizerReducer';
import { shipCattleReducer } from './shipCattleReducer';
import ShipPOReducer from '../../apps/shippingTracker/modules/shippo/reducers/shipPoReducer';
import TreatmentProtocolReducer from './treatmentProtocolReducer';
import treatmentsReducer from './treatmentsSlice';
import YardLayoutReducer from './yardLayoutReducer';
import { ownerReducer } from '../../modules/cattleManagement/owners/ownerSlice';
import { penReducer } from './penReducer';
import { penHistoryReducer } from './penHistorySlice';
import { TruckReducer } from './truckReducer';
import { LoaderReducer } from './loaderSlice';
import { MixingBinReducer } from './mixingBinReducer';
import LotPageSortingReducer from './LotPageSortingReducer';
import { YardageSlice } from './yardageSlice';
import { LocationSlice } from './locationSlice';
import { DestinationSlice } from './destinationSlice';
import userManagementReducer from '../../apps/feedlotManager/modules/settings/userManagement/reducer/userManagementSlice';
import { BuyerSlice } from './buyerSlice';
import { OwnerSettingSlice } from './ownerSettingSlice';
import { TechSlice } from './techSlice';
import { RiderSlice } from './riderSlice';
import { TankSlice } from './tankSlice';
import { appLoaderReducer } from '../../apps/common/modules/appLoader/slices/appLoaderSlice';
import feedBillUpdate from './feedBillUpdate';
import { TruckingCompanySlice } from './truckingCompanySlice';
import { authReducer } from '../../apps/common/modules/auth/authSlice';
import { rootApi } from '../Apis/rootApi';
import { VendorSlice } from './vendorSlice';
import { feedCallPageReducer } from '../../apps/feedlotManager/modules/feed/feedCall/slices/feedCallPageSlice';

const rootReducer = combineReducers({
    common: CommonReducer,
    billing: billingReducer,
    buyPO: BuyPoReducer,
    shipPO: ShipPOReducer,
    databricks: databricksReducer,
    tenantSetting: tenantSettingsReducer,
    receiveCattle: ReceiveCattleReducer,
    ration: RationReducer,
    shipCattle: shipCattleReducer,
    moveCattle: moveCattleReducer,
    penRationTemplates: PenRationTemplatesReducer,
    feedCall: FeedCallReducer,
    lot: lotReducer,
    penRationSchedule: PenRationScheduleReducer,
    feedCallRoutesReducer: FeedCallRoutesReducer,
    medicalItems: MedicalItemsReducer,
    medicalInventory: MedicalInventoryReducer,
    treatmentProtocols: TreatmentProtocolReducer,
    processingProtocols: ProcessingProtocolReducer,
    diagnosis: DiagnosisReducer,
    feedStatus: FeedStatusReducer,
    manageCommodities: manageCommoditiesReducer,
    scaleTicket: ScaleTicketReducer,
    feedRationOrder: FeedRationOrderReducer,
    treatments: treatmentsReducer,
    mortalityReports: MortalityReportsReducer,
    processingLot: processingLotReducer,
    feedRouteCreation: FeedRouteCreationReducer,
    hotList: HotListReducer,
    yardLayout: YardLayoutReducer,
    commodityInventoryReport: CommodityInventoryReportReducer,
    manualFeedEntry: ManuaLFeedEntryReducer,
    feedReport: FeedReportReducer,
    shipCattleRealizer: ShipCattleRealizerReducer,
    hospital: HospitalReducer,
    cattlePoCalendar: cattlePoCalendarReducer,
    lotId: FinanceLotId,
    lotSorting: LotPageSortingReducer,
    yardSheet: yardSheetReducer,
    owner: ownerReducer,
    pen: penReducer,
    truck: TruckReducer,
    loader: LoaderReducer,
    mixingBin: MixingBinReducer,
    penHistory: penHistoryReducer,
    yardage: YardageSlice,
    location: LocationSlice,
    destination: DestinationSlice,
    buyer: BuyerSlice,
    rider: RiderSlice,
    ownerSetting: OwnerSettingSlice,
    tech: TechSlice,
    tank: TankSlice,
    userManagement: userManagementReducer,
    appLoader: appLoaderReducer,
    feedBillUpdate: feedBillUpdate,
    truckingCompany: TruckingCompanySlice,
    vendor: VendorSlice,
    auth: authReducer,
    [rootApi.reducerPath]: rootApi.reducer,
    feedCallPage: feedCallPageReducer,
});

export default rootReducer;
